import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const productsAdapter = createEntityAdapter()

const initialState = productsAdapter.getInitialState()

export const productSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getProducts: builder.query({
            query: () => '/products/product/',
            transformResponse: responseData => {
                console.log(productsAdapter.setAll(initialState, responseData))
                return productsAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'Products', id: "LIST" },
                ...result.ids.map(id => ({ type: 'Products', id }))
            ]
        }),
        getProductsById: builder.query({
            query: (id)=>({
                url:`/products/product/${id}/`,
                method: 'GET',
            }),
            providesTags: ['Products']
        }),
        addProduct: builder.mutation({
            query: (product) => {
            console.log(product);
            return{
                url: '/products/product/',
                method: 'POST',
                body: product
            }
        },
            invalidatesTags: ['Products']
        }),
        updateProduct: builder.mutation({
           
            query: (updateProduct) => {
                console.log("here");
                console.log(updateProduct);
                return{
                url: `/products/product/${updateProduct.get('id')}/`,
                method: 'PATCH',
                body: updateProduct
                }
            },
            invalidatesTags: ['Products']
        }),
        deleteProduct: builder.mutation({
            query: ({ id }) => {
                console.log(id);
                return{
                url: `/products/product/${id}/`,
                method: 'DELETE',
                body: id
            }},
            invalidatesTags: ['Products']
        }),
    })
})

export const {
    useGetProductsQuery,
    useAddProductMutation,
    useUpdateProductMutation,
    useDeleteProductMutation,
    useGetProductsByIdQuery
} = productSlice

// returns the query result object
export const selectProductsResult = productSlice.endpoints.getProducts.select()

// Creates memoized selector
const selectProductssData = createSelector(
    selectProductsResult,
    productsResult => productsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllProducts,
    selectById: selectProductById,
    selectIds: selectIds
    // Pass in a selector that returns the posts slice of state
} = productsAdapter.getSelectors(state => selectProductssData(state) ?? initialState)