import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";
import { useGetContactsByIdQuery } from "../../../../services/contactSlice";
import BlobToFile from "../forms/utils/blobToFile";
import { useUpdateContactMutation } from "../../../../services/contactSlice";
import urlToFile from "../forms/utils/urlToFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBell,
  faSignInAlt,
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const ContactEditPage = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <EditContactPages />
      </Grid>
    </Grid>
  );
};

const EditContactPages = () => {
  const { id } = useParams();
  const Contacts = useGetContactsByIdQuery(id);
  const [File, setFile] = useState([]);
  const [updateContact, setUpdateContact] = useState([]);
  const [initialFValues, setInitialFValues] = useState({
    full_name: "",
    is_replied: "false",
    contact_no: "",
    email: "",
    message: "",
  });
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);
  const history = useNavigate();

  useEffect(() => {
    if (Contacts.data) {
      setInitialFValues({
        is_replied: Contacts.data.is_replied.toString(),
        full_name: Contacts.data.full_name,
        contact_no: Contacts.data.contact_no,
        email: Contacts.data.email,
        message: Contacts.data.message,
      });
    }
  }, [Contacts.data]);

  const { values, handleImageUpload, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    true
  );

  const [editContact] = useUpdateContactMutation();

  console.log(values);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if(values.is_replied)
      formData.append("is_replied", values.is_replied);
    formData.append("id", id);
    editContact(formData)
      .then((res) => {
        setSuccesMessege("Successfully Update Contact Page Data.");
        setTimeout(() => {
          window.location.reload();
          history("/dashboard/contact");
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur. Please Try Again Later.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <Box className="table-design-background">
      <Typography className="dashboard-home-page-text">
        Update Contact Page
      </Typography>
      <Box style={{ padding: "1.5rem" }}>
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12} style={{ paddingLeft: "1.5rem" }}>
              <Grid container spacing={3}>
                <Box className="full-name-design">
                  <Typography className="full-name-font">
                    Full Name: {values.full_name}
                  </Typography>
                </Box>
                <Box className="full-name-design">
                  <Typography className="full-name-font">
                    Contact No: {values.contact_no}
                  </Typography>
                </Box>
                <Box className="full-name-design">
                  <Typography className="full-name-font">
                    Email: {values.email}
                  </Typography>
                </Box>
                <Box className="full-name-design">
                  <Typography className="full-name-font">
                    Message: {values.message}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Controls.RadioGroup
                name="is_replied"
                label="Is Replied"
                value={values.is_replied}
                onChange={handleInputChange}
                items={[
                  { id: "false", title: "False" },
                  { id: "true", title: "True" },
                ]}
              />
            </Grid>
            <Grid style={{ marginTop: "12px" }}>
              <Controls.Button
                type="submit"
                text="Submit"
                onClick={handleSubmit}
                backgroundColor="#1b284b"
              />
            </Grid>
          </Grid>
        </Form>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ContactEditPage;
