import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const imageGallerysAdapter = createEntityAdapter()

const initialState = imageGallerysAdapter.getInitialState()

export const imageGallerySlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getImageGallerys: builder.query({
            query: () => '/basic/image-gallery/',
            transformResponse: responseData => {
                console.log(imageGallerysAdapter.setAll(initialState, responseData))
                return imageGallerysAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'ImageGallerys', id: "LIST" },
                ...result.ids.map(id => ({ type: 'ImageGallerys', id }))
            ]
        }),
        getActiveImageGallerys: builder.query({
            query: () => '/basic/image-gallery/?is_active=True',
            transformResponse: responseData => {
                console.log(imageGallerysAdapter.setAll(initialState, responseData))
                return imageGallerysAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'ImageGallerys', id: "LIST" },
                ...result.ids.map(id => ({ type: 'ImageGallerys', id }))
            ]
        }),
        getImageGallerysById: builder.query({
            query: (id)=>({
                url:`/basic/image-gallery/${id}/`,
                method: 'GET',
            }),
            providesTags: ['ImageGallerys']
        }),
        addImageGallery: builder.mutation({
            query: (imageGallery) => {
            console.log(imageGallery);
            return{
                url: '/basic/image-gallery/',
                method: 'POST',
                body: imageGallery
            }
        },
            invalidatesTags: ['ImageGallerys']
        }),
        updateImageGallery: builder.mutation({
           
            query: (updateImageGallery) => {
                console.log("here");
                console.log(updateImageGallery);
                return{
                url: `/basic/image-gallery/${updateImageGallery.get('id')}/`,
                method: 'PATCH',
                body: updateImageGallery
                }
            },
            invalidatesTags: ['ImageGallerys']
        }),
        deleteImageGallery: builder.mutation({
            query: ({ id }) => {
                console.log(id);
                return{
                url: `/basic/image-gallery/${id}/`,
                method: 'DELETE',
                body: id
            }},
            invalidatesTags: ['ImageGallerys']
        }),
    })
})

export const {
    useGetImageGallerysQuery,
    useAddImageGalleryMutation,
    useUpdateImageGalleryMutation,
    useDeleteImageGalleryMutation,
    useGetImageGallerysByIdQuery,
    useGetActiveImageGallerysQuery,
} = imageGallerySlice

// returns the query result object
export const selectImageGallerysResult = imageGallerySlice.endpoints.getImageGallerys.select()

// Creates memoized selector
const selectImageGalleryssData = createSelector(
    selectImageGallerysResult,
    imageGallerysResult => imageGallerysResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllImageGallerys,
    selectById: selectImageGalleryById,
    selectIds: selectIds
    // Pass in a selector that returns the posts slice of state
} = imageGallerysAdapter.getSelectors(state => selectImageGalleryssData(state) ?? initialState)