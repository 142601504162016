import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/sidebar";
import { Box, Grid, Typography } from "@mui/material";
import Header from "../../component/header";
import BasicTable from "../tables/BasicTable";
import {
  useGetServicesQuery,
  useUpdateServiceMutation,
  useDeleteServiceMutation,
  useAddServiceMutation,
} from "../../../../services/serviceSlice";
import ServiceAddButton from "./serviceAddButton";

const DashboardServices = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <DashboardServicesPages />
      </Grid>
    </Grid>
  );
};

const DashboardServicesPages = () => {
  const { data, isLoading, isSuccess, isError, error } =
    useGetServicesQuery("getServices");

  console.log(data);

  const [deleteService] = useDeleteServiceMutation();

  const deleteConfirm = (e, id) => {
    e.preventDefault();
    console.log(id);
    deleteService({ id });
  };

  let headers = [];
  let tableData = [];
  let content;
  if (isLoading) {
    content = <p>"Loading..."</p>;
  } else if (isSuccess) {
    headers = [
      "Service Name",
      "Description",
      "No of Visit",
      "Price",
      "Time Frame",
    ];
    tableData = [];
    data.ids.forEach((key) => {
      tableData.push({
        "Service Name": data.entities[key].service_name,
        Description: data.entities[key].description,
        "No of Visit": data.entities[key].no_of_visit,
        Price: data.entities[key].price,
        "Time Frame": data.entities[key].time_frame,
        id: data.entities[key].id,
      });
    });
  }
  return (
    <>
      <Box className="table-design-background">
        <Grid container>
          <Grid item xs={6}>
            <Typography className="dashboard-home-page-text">
              Services Lists
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Link to="/dashboard/new/services">
              <ServiceAddButton />
            </Link>
          </Grid>
        </Grid>
        <Box style={{ padding: "1rem" }}>
          <BasicTable
            headers={headers}
            data={tableData}
            isDelete={true}
            deleteColor={"#f50057"}
            isEdit={true}
            editColor={"#3f51b5"}
            addColor={""}
            tableName={"services"}
            deleteConfirm={deleteConfirm}
          />
        </Box>
      </Box>
    </>
  );
};
export default DashboardServices;
