import { Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import Footer from "../component/footer";
import Header from "../component/header";
import { FaChevronRight } from "react-icons/fa";
import Skin from "../../../static/images/skin.png";
import Skeleton , {SkeletonTheme}from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useGetBlogsQuery } from "../../../services/blogSlice";
import {
    HiArrowNarrowRight,
    HiOutlineMail,
    HiOutlineLocationMarker,
  } from "react-icons/hi";
  import Articles from "../../../static/images/articles.jpeg";

const BlogPage = () => {
  return (
    <Box>
      <Header />
      <BlogDesignPage />
      <Footer />
    </Box>
  );
};

const BlogDesignPage = () => {
  const {
    data,
    isLoading,
    isSuccess,
    isError,
    error
} = useGetBlogsQuery('getBlogs')
console.log("here");
console.log(data);
  return (
    <Box>
      <Box className="new-box-color">
        <Container>
          <Grid container spacing={7}>
            <Grid item md={7} xs={12}>
              <Box style={{ marginTop: "5rem" }}>
                <Typography variant="h1" className="body-h1-text">
                  Blogs
                </Typography>
                <Box style={{ marginTop: "2rem", textAlign: "start" }}>
                  <span className="">
                    <Link to="/" className="footer-service-link1">
                      Home
                    </Link>
                    <FaChevronRight
                      style={{ marginLeft: "15px", marginRight: "15px" }}
                      size={12}
                    />
                    <Link to="/blog" className="footer-service-link1">
                      Blogs
                    </Link>
                  </span>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={0} md={1}></Grid>
            <Grid item md={4} xs={12}>
              <img
                src={Skin}
                className="logo-image-width image-margin"
                alt="service-images"
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box style={{ marginTop: "4.5rem", textAlign: "start" }}>
        <Container>
          <Grid container spacing={3}>
          {data===undefined?
            <>
            <Grid item md={4} xs={12}>
            <Box className="new-cards-shadow">
            <SkeletonTheme color="grey" highlightColor="#444">
                <p>
                <Skeleton height={250} width={300} count={1} />
                </p> 
            </SkeletonTheme>
            <Box style={{ padding: "2rem" }}>
                <Typography className="new-articles-text" variant="h1">
                <Skeleton/>
                </Typography>
                <Box style={{ marginTop: "1rem" }}>
                <Skeleton/>
                </Box>
            </Box>
            </Box>
        </Grid>
        <Grid item md={4} xs={12}>
            <Box className="new-cards-shadow">
            <SkeletonTheme color="grey" highlightColor="#444">
                <p>
                <Skeleton height={250} width={300} count={1} />
                </p> 
            </SkeletonTheme>
            <Box style={{ padding: "2rem" }}>
                <Typography className="new-articles-text" variant="h1">
                <Skeleton/>
                </Typography>
                <Box style={{ marginTop: "1rem" }}>
                <Skeleton/>
                </Box>
            </Box>
            </Box>
        </Grid>
        <Grid item md={4} xs={12}>
            <Box className="new-cards-shadow">
            <SkeletonTheme color="grey" highlightColor="#444">
                <p>
                <Skeleton height={250} width={300} count={1} />
                </p> 
            </SkeletonTheme>
            <Box style={{ padding: "2rem" }}>
                <Typography className="new-articles-text" variant="h1">
                <Skeleton/>
                </Typography>
                <Box style={{ marginTop: "1rem" }}>
                <Skeleton/>
                </Box>
            </Box>
            </Box>
        </Grid>
            </>:<>{data.ids.map((key)=>(
        <Grid item md={4} xs={12}>
            <Box className="new-cards-shadow">
            <img src={data.entities[key].image} className="logo-image-width" />
            <Box style={{ padding: "2rem" }}>
                <Typography className="new-articles-text" variant="h1">
                {data.entities[key].title}
                </Typography>
                <Box style={{ marginTop: "1rem" }}>
                <Link to={`/blogs/${data.entities[key].id}`} className="text-fonts1">
                    Read More{" "}
                    <HiArrowNarrowRight className="contact-us-button-icon" />
                </Link>
                </Box>
            </Box>
            </Box>
        </Grid>
        ))}</>}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default BlogPage;
