import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const homePagesAdapter = createEntityAdapter()

const initialState = homePagesAdapter.getInitialState()

export const homePageSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getHomePages: builder.query({
            query: () => '/basic/homepage/',
            transformResponse: responseData => {
                console.log(homePagesAdapter.setAll(initialState, responseData))
                return homePagesAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'HomePages', id: "LIST" },
                ...result.ids.map(id => ({ type: 'HomePages', id }))
            ]
        }),
        getActiveHomePages: builder.query({
            query: () => '/basic/homepage/?is_active=True',
            transformResponse: responseData => {
                console.log(homePagesAdapter.setAll(initialState, responseData))
                return homePagesAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'HomePages', id: "LIST" },
                ...result.ids.map(id => ({ type: 'HomePages', id }))
            ]
        }),
        
        getHomePagesById: builder.query({
            query: (id)=>({
                url:`/basic/homepage/${id}/`,
                method: 'GET',
            }),
            providesTags: ['HomePages']
        }),
        addHomePage: builder.mutation({
            query: (homePage) => {
            console.log(homePage);
            return{
                url: '/basic/homepage/',
                method: 'POST',
                body: homePage
            }
        },
            invalidatesTags: ['HomePages']
        }),
        updateHomePage: builder.mutation({
           
            query: (updateHomePage) => {
                console.log("here");
                console.log(updateHomePage);
                return{
                url: `/basic/homepage/${updateHomePage.get('id')}/`,
                method: 'PATCH',
                body: updateHomePage
                }
            },
            invalidatesTags: ['HomePages']
        }),
        deleteHomePage: builder.mutation({
            query: ({ id }) => {
                console.log(id);
                return{
                url: `/basic/homepage/${id}/`,
                method: 'DELETE',
                body: id
            }},
            invalidatesTags: ['HomePages']
        }),
    })
})

export const {
    useGetHomePagesQuery,
    useAddHomePageMutation,
    useUpdateHomePageMutation,
    useDeleteHomePageMutation,
    useGetHomePagesByIdQuery,
    useGetActiveHomePagesQuery,
} = homePageSlice

// returns the query result object
export const selectHomePagesResult = homePageSlice.endpoints.getHomePages.select()

// Creates memoized selector
const selectHomePagessData = createSelector(
    selectHomePagesResult,
    homePagesResult => homePagesResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllHomePages,
    selectById: selectHomePageById,
    selectIds: selectIds
    // Pass in a selector that returns the posts slice of state
} = homePagesAdapter.getSelectors(state => selectHomePagessData(state) ?? initialState)