import React, { useEffect, useState } from "react";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import { Grid, Typography } from "@mui/material";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";
import { useAddAboutPageMutation } from "../../../../services/aboutPageSlice";
import BlobToFile from "../forms/utils/blobToFile";
import { Box } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBell,
  faSignInAlt,
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const AddAboutPage = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <AddAboutPagePages />
      </Grid>
    </Grid>
  );
};

const AddAboutPagePages = () => {
  const [File1, setFile1] = useState([]);
  const [File2, setFile2] = useState([]);
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);
  const initialFValues = {
    image1: [],
    image2: [],
    title: "",
    subtitle: "",
    description: "",
    random1: "",
    random2: "",
    random3: "",
    is_active: "false",
  };
  const { values, handleImageUpload, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    false
  );
  const history = useNavigate();

  const [addAboutPage] = useAddAboutPageMutation();

  useEffect(() => {
    handleImageUpload("image1", File1);
  }, [File1]);

  useEffect(() => {
    handleImageUpload("image2", File2);
  }, [File2]);

  const thisFile = (e) => {
    console.log(e.target.files[0]);
    console.log(typeof e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    var isTrueSet = values.is_active === "true";
    console.log(isTrueSet);
    var file1 = BlobToFile(values.image1, "homePage1");
    if (file1.length !== 0) {
      formData.append("image1", file1, "homePage1.jpg");
    }
    var file2 = BlobToFile(values.image2, "homePage2");
    if (file2.length !== 0) {
      formData.append("image2", file2, "homePage2.jpg");
    }
    formData.append("title", values.title);
    formData.append("subtitle", values.subtitle);
    formData.append("is_active", isTrueSet);
    formData.append("description", values.description);
    formData.append("random1", values.random1);
    formData.append("random2", values.random2);
    formData.append("random3", values.random3);
    addAboutPage(formData)
      .then((res) => {
        setSuccesMessege("Successfully Added About Page Data.");
        setTimeout(() => {
          history("/dashboard/about-pages");
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur. Please Try Again Later.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <Box className="table-design-background">
      <Typography className="dashboard-home-page-text">
        Add About Page
      </Typography>
      <Box style={{ padding: "1.5rem" }}>
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={6}>
              <Controls.Input
                name="title"
                label="Title"
                value={values.title}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="subtitle"
                label="Sub-Title"
                value={values.subtitle}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="description"
                label="Description"
                value={values.description}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="random1"
                label="Stats1"
                value={values.random1}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="random2"
                label="Stats2"
                value={values.random2}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="random3"
                label="Stats3"
                value={values.random3}
                onChange={handleInputChange}
              />
              <Controls.RadioGroup
                name="is_active"
                label="Is Active"
                value={values.is_active}
                onChange={handleInputChange}
                items={[
                  { id: "false", title: "False" },
                  { id: "true", title: "True" },
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <Box style={{ marginBottom: "12px" }}>
                <Controls.Image setFile={setFile1} aspectRatio={471 / 430} />
              </Box>

              <Controls.Image setFile={setFile2} aspectRatio={471 / 440} />
            </Grid>
            <Grid>
              <Controls.Button
                type="submit"
                text="Submit"
                backgroundColor="#1b284b"
                onClick={handleSubmit}
              />
            </Grid>
          </Grid>
        </Form>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default AddAboutPage;
