import React, { useEffect, useState } from "react";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import DashboardHome from "../containers/dashboard/pages/dashboardhome";
import Home from "../containers/templates/pages/home";
import DashboardServices from "../containers/dashboard/pages/serviceDashboard/serviceDashboardHome";
import AddService from "../containers/dashboard/pages/serviceDashboard/serviceNewPage";
import ServiceEditPage from "../containers/dashboard/pages/serviceDashboard/serviceEditPage";
import DashboardProducts from "../containers/dashboard/pages/productDashboard/productDashboardHome";
import AddProduct from "../containers/dashboard/pages/productDashboard/productNewPage";
import ProductEditPage from "../containers/dashboard/pages/productDashboard/productEditPage";
import DashboardBlogs from "../containers/dashboard/pages/blogDashboard/blogDashboardHome";
import AddBlog from "../containers/dashboard/pages/blogDashboard/blogNewPage";
import BlogEditPage from "../containers/dashboard/pages/blogDashboard/blogEditPage";
import DashboardHomePages from "../containers/dashboard/pages/homePageDashboard/homePageDashboardHome";
import AddHomePage from "../containers/dashboard/pages/homePageDashboard/homePageNewPage";
import HomePageEditPage from "../containers/dashboard/pages/homePageDashboard/homePageEditPage";
import DashboardAboutPages from "../containers/dashboard/pages/aboutDashboard/aboutPageDashboardHome";
import AddAboutPage from "../containers/dashboard/pages/aboutDashboard/aboutPageNewPage";
import AboutPageEditPage from "../containers/dashboard/pages/aboutDashboard/aboutPageEditPage";
import DashboardImageGallerys from "../containers/dashboard/pages/imageGalleryDashboard/imageGalleryDashboardHome";
import AddImageGallery from "../containers/dashboard/pages/imageGalleryDashboard/imageGalleryNewPage";
import ImageGalleryEditPage from "../containers/dashboard/pages/imageGalleryDashboard/imageGalleryEditPage";
import ServicesDetailPage from "../containers/templates/pages/servicesDetailPage";
import BlogDetailPage from "../containers/templates/pages/blogDetailPage";
import BlogPage from "../containers/templates/pages/blogPage";
import Product from "../containers/templates/pages/product";
import DashboardContacts from "../containers/dashboard/pages/contactDashboard/contactDashboardHome";
import ContactEditPage from "../containers/dashboard/pages/contactDashboard/contactEditPage";
import DashboardAppointments from "../containers/dashboard/pages/appointmentDashboard/appointmentDashboardHome";
import AppointmentEditPage from "../containers/dashboard/pages/appointmentDashboard/appointmentEditPage";
import DashboardDoctors from "../containers/dashboard/pages/doctorDashboard/doctorDashboardHome";

import Error from "../containers/templates/pages/errorPage";
import AdminLogin from "../containers/dashboard/component/adminLogin";
import Cookies from "js-cookie";
import AddDoctor from "../containers/dashboard/pages/doctorDashboard/doctorNewPage";
import DoctorEditPage from "../containers/dashboard/pages/doctorDashboard/doctorEditPage";

const Routing = () => {
  const Access_token = Cookies.get("access");
  const Refresh_token = Cookies.get("refresh");
  const email = Cookies.get("username");
  const is_admin = Cookies.get("is_admin");
  console.log(Access_token);
  const TOKEN_KEY = { Access_token, Refresh_token, email, is_admin };
  const [isAuth, setIsAuth] = useState(false);
  console.log(TOKEN_KEY);

  useEffect(() => {
    if (
      TOKEN_KEY["access"] == undefined &&
      TOKEN_KEY["refresh"] == undefined &&
      TOKEN_KEY["username"] == undefined &&
      TOKEN_KEY["is_admin"] == undefined
    ) {
      setIsAuth(false);
      console.log("token xaina");
    } else {
      setIsAuth(true);
      console.log("token xa");
    }
  }, []);
  return (
    <HashRouter>
      <Routes>
        <Route path="/" exact element={<Home />} />
        {isAuth == true ? (
          <>
            <Route path="/dashboard/home" exact element={<DashboardHome />} />
            <Route
              path="/dashboard/services"
              exact
              element={<DashboardServices />}
            />
            <Route
              path="/dashboard/new/services"
              exact
              element={<AddService />}
            />
            <Route
              path="/dashboard/services/edit/:id"
              exact
              element={<ServiceEditPage />}
            ></Route>
            <Route
              path="/dashboard/products"
              exact
              element={<DashboardProducts />}
            />
            <Route
              path="/dashboard/new/products"
              exact
              element={<AddProduct />}
            />
            <Route
              path="/dashboard/products/edit/:id"
              exact
              element={<ProductEditPage />}
            ></Route>
            <Route path="/dashboard/blogs" exact element={<DashboardBlogs />} />
            <Route path="/dashboard/new/blogs" exact element={<AddBlog />} />
            <Route
              path="/dashboard/blogs/edit/:id"
              exact
              element={<BlogEditPage />}
            ></Route>
            <Route
              path="/dashboard/home-pages"
              exact
              element={<DashboardHomePages />}
            />
            <Route
              path="/dashboard/new/home-pages"
              exact
              element={<AddHomePage />}
            />
            <Route
              path="/dashboard/home-pages/edit/:id"
              exact
              element={<HomePageEditPage />}
            ></Route>
            <Route
              path="/dashboard/about-pages"
              exact
              element={<DashboardAboutPages />}
            />
            <Route
              path="/dashboard/new/about-pages"
              exact
              element={<AddAboutPage />}
            />
            <Route
              path="/dashboard/about-pages/edit/:id"
              exact
              element={<AboutPageEditPage />}
            ></Route>
            <Route
              path="/dashboard/image-gallerys"
              exact
              element={<DashboardImageGallerys />}
            />
            <Route
              path="/dashboard/new/image-gallerys"
              exact
              element={<AddImageGallery />}
            />
            <Route
              path="/dashboard/image-gallerys/edit/:id"
              exact
              element={<ImageGalleryEditPage />}
            ></Route>
            <Route
              path="/dashboard/contact"
              exact
              element={<DashboardContacts />}
            />
            <Route
              path="/dashboard/contact/edit/:id"
              exact
              element={<ContactEditPage />}
            ></Route>
            <Route
              path="/dashboard/appointment"
              exact
              element={<DashboardAppointments />}
            />
            <Route
              path="/dashboard/appointment/edit/:id"
              exact
              element={<AppointmentEditPage />}
            ></Route>

<Route
              path="/dashboard/doctors"
              exact
              element={<DashboardDoctors />}
            />
            <Route
              path="/dashboard/new/doctors"
              exact
              element={<AddDoctor />}
            />
            <Route
              path="/dashboard/doctors/edit/:id"
              exact
              element={<DoctorEditPage />}
            ></Route>
          </>
        ) : (
          <Route path="/admin/login" exact element={<AdminLogin />} />
        )}

        <Route
          path="/services/:id"
          exact
          element={<ServicesDetailPage />}
        ></Route>
        <Route path="/blogs/:id" exact element={<BlogDetailPage />}></Route>
        <Route path="/blog" exact element={<BlogPage />}></Route>
        <Route path="/doctor" exact element={<Product />}></Route>
        <Route path="*" exact element={<Error />}></Route>
      </Routes>
    </HashRouter>
  );
};

export default Routing;
