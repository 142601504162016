import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const doctorsAdapter = createEntityAdapter()

const initialState = doctorsAdapter.getInitialState()

export const doctorSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getDoctor: builder.query({
            query: () => '/doctors/doctor/',
            transformResponse: responseData => {
                return doctorsAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'Doctors', id: "LIST" },
                ...result.ids.map(id => ({ type: 'Doctors', id }))
            ]
        }),
        getDoctorById: builder.query({
            query: (id)=>({
                url:`/doctors/doctor/${id}/`,
                method: 'GET',
            }),
            providesTags: ['Doctors']
        }),
        addDoctor: builder.mutation({
            query: (doctor) => {
            return{
                url: '/doctors/doctor/',
                method: 'POST',
                body: doctor
            }
        },
            invalidatesTags: ['Doctors']
        }),
        updateDoctor: builder.mutation({
           
            query: (updateDoctor) => {
                return{
                url: `/doctors/doctor/${updateDoctor.get('id')}/`,
                method: 'PATCH',
                body: updateDoctor
                }
            },
            invalidatesTags: ['Doctors']
        }),
        deleteDoctor: builder.mutation({
            query: ({ id }) => {
                return{
                url: `/doctors/doctor/${id}/`,
                method: 'DELETE',
                body: id
            }},
            invalidatesTags: ['Doctors']
        }),
    })
})

export const {
    useGetDoctorQuery,
    useAddDoctorMutation,
    useUpdateDoctorMutation,
    useDeleteDoctorMutation,
    useGetDoctorByIdQuery
} = doctorSlice

// returns the query result object
export const selectDoctorsResult = doctorSlice.endpoints.getDoctor.select()

// Creates memoized selector
const selectDoctorsData = createSelector(
    selectDoctorsResult,
    doctorsResult => doctorsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllDoctors,
    selectById: selectDoctorById,
    selectIds: selectIds
    // Pass in a selector that returns the posts slice of state
} = doctorsAdapter.getSelectors(state => selectDoctorsData(state) ?? initialState)