import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

const AdminLogin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const history = useNavigate();

  const onUserName = (e) => setUsername(e.target.value);
  const onPassword = (e) => setPassword(e.target.value);

  const submitBtn = (e) => {
    e.preventDefault();
    if (username === "info@dhangadiskin.com" && password === "skin12345") {
      Cookies.set("username", username);
      Cookies.set(
        "access",
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eeyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoicmVmcm"
      );
      Cookies.set(
        "refresh",
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoicmVmcmeyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eeyJ0"
      );
      Cookies.set("is_admin", true);
      setSuccessMessage("Successfully Logged In.");
      setTimeout(() => {
        history("/dashboard/home");
        window.location.reload();
      }, 3000);
    } else {
      setErrorMessage("Email or Password does not match.");
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-slate-50">
      <div
        className="bg-black h-[450px]  rounded-lg p-8 max-w-md w-full "
        style={{ boxShadow: "rgb(0 0 0 / 85%) 13px 14px 8px -9px" }}
      >
        <h3 className="text-2xl font-semibold text-center text-white mb-6 ">
          Admin Panel
        </h3>
        <form onSubmit={submitBtn} className="mt-[2rem]">
          <div className="mb-4">
            <label
              htmlFor="username"
              className="block text-sm font-medium text-white mb-2 text-left"
            >
              Username
            </label>
            <input
              type="text"
              id="username"
              name="username"
              className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              placeholder="Enter Admin Email"
              onChange={onUserName}
              autoComplete="off"
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-white mb-2 text-left"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              placeholder="Enter Admin Password"
              onChange={onPassword}
              autoComplete="off"
            />
          </div>
          <button
            type="submit"
            className=" bg-green-800 text-white py-2 rounded-lg shadow-md hover:bg-[#5a187a] focus:outline-none focus:ring-2 focus:ring-indigo-500 mt-10"
            style={{
              clipPath:
                "polygon(10% 0%, 100% 0, 100% 55%, 90% 100%, 0 100%, 0% 50%)",
              padding: "8px 40px",
            }}
          >
            Log In
          </button>
        </form>
        {successMessage && (
          <div className="mt-6 p-4 bg-green-100 text-green-800 rounded-lg flex items-center">
            <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
            <span>{successMessage}</span>
          </div>
        )}
        {errorMessage && (
          <div className="mt-6 p-4 bg-red-100 text-red-800 rounded-lg flex items-center">
            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
            <span>{errorMessage}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminLogin;
