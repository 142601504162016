import React, { useEffect, useState } from "react";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import { Box, Grid, Typography } from "@mui/material";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";
import { useAddBlogMutation } from "../../../../services/blogSlice";
import BlobToFile from "../forms/utils/blobToFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBell,
  faSignInAlt,
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const AddBlog = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <AddBlogPages />
      </Grid>
    </Grid>
  );
};

const AddBlogPages = () => {
  const [File, setFile] = useState([]);
  const initialFValues = {
    image: [],
    title: "",
    subtitle: "",
    text: "",
  };
  const { values, handleImageUpload, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    false
  );
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);
  const history = useNavigate();

  const [addBlog] = useAddBlogMutation();

  useEffect(() => {
    handleImageUpload("image", File);
  }, [File]);

  const thisFile = (e) => {
    console.log(e.target.files[0]);
    console.log(typeof e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    var file = BlobToFile(values.image, "blog");
    console.log(file);
    console.log(typeof file);
    if (file.length !== 0) {
      formData.append("image", file, "blog.jpg");
    }
    formData.append("title", values.title);
    formData.append("subtitle", values.subtitle);
    formData.append("text", values.text);
    addBlog(formData)
      .then((res) => {
        setSuccesMessege("Successfully Added Blog Page Data.");
        setTimeout(() => {
          history("/dashboard/blogs");
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur. Please Try Again Later.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <Box className="table-design-background">
      <Typography className="dashboard-home-page-text">
        Add Blog Page
      </Typography>
      <Box style={{ padding: "1.5rem" }}>
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={6}>
              <Controls.Input
                name="title"
                label="Title"
                value={values.title}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="subtitle"
                label="Sub-Title"
                value={values.subtitle}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="text"
                label="Text"
                value={values.text}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Image setFile={setFile} aspectRatio={368 / 246} />
            </Grid>
            <Grid style={{ marginTop: "12px" }}>
              <Controls.Button
                type="submit"
                text="Submit"
                backgroundColor="#1b284b"
                onClick={handleSubmit}
              />
            </Grid>
          </Grid>
        </Form>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default AddBlog;
