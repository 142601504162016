import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { serviceSlice } from './services/serviceSlice';
import {productSlice} from './services/productSlice';
import {blogSlice} from './services/blogSlice';
import {homePageSlice} from './services/homePageSlice';
import {aboutPageSlice} from './services/aboutPageSlice';
import {imageGallerySlice} from './services/imageGallerySlice';
import {appointmentSlice} from './services/appointmentSlice';
import {contactSlice} from './services/contactSlice';

store.dispatch(serviceSlice.endpoints.getServices.initiate())
store.dispatch(productSlice.endpoints.getProducts.initiate())
store.dispatch(blogSlice.endpoints.getBlogs.initiate())
store.dispatch(homePageSlice.endpoints.getHomePages.initiate())
store.dispatch(aboutPageSlice.endpoints.getAboutPages.initiate())
store.dispatch(imageGallerySlice.endpoints.getImageGallerys.initiate())
store.dispatch(appointmentSlice.endpoints.getAppointments.initiate())
store.dispatch(contactSlice.endpoints.getContacts.initiate())


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
