import React, { useEffect, useState } from "react";
import Controls from "../../dashboard/pages/forms/controls/Controls";
import { Box, Grid, Typography } from "@mui/material";
import { useForm, Form } from "../../dashboard/pages/forms/useForm";
import { useGetServicesQuery } from "../../../services/serviceSlice";
import { useAddAppointmentMutation } from "../../../services/appointmentSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBell,
  faSignInAlt,
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

const Appointment_Page = () => {
  const { data, isLoading, isSuccess, isError, error } =
    useGetServicesQuery("getServices");

  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);

  const services = [];
  if (isSuccess) {
    data.ids.forEach((key) => {
      console.log(data[key]);
      services.push({ id: key, title: data.entities[key].service_name });
    });
  }

  const initialFValues = {
    id: 0,
    full_name: "",
    email: "",
    contact_no: "",
    date_and_time: new Date(),
    service_name: "",
    message: "",
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handleMultipleInput,
    resetForm,
    valueArray,
  } = useForm(initialFValues, true);

  const [addAppointment] = useAddAppointmentMutation();

  const handleSubmit = (e) => {
    console.log(e);
    console.log(values.date_and_time);
    e.preventDefault();
    const formData = new FormData();
    formData.append("full_name", values.full_name);
    formData.append("email", values.email);
    formData.append("contact_no", values.contact_no);
    formData.append("date_and_time", values.date_and_time.toISOString());
    formData.append("message", values.message);
    formData.append("service_name", values.service_name);
    addAppointment(formData)
      .then((res) => {
        setSuccesMessege("Your Appointment Has Been Booked.");
        setTimeout(() => {
          window.location.reload();
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege(
          "Some Error Occur While Booking Appointment. Please Try Again Later."
        );
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <Grid container spacing={5} id="appointment" className=" font-Barlow">
      <Grid item md={6} xs={12}>
        <Box>
          <Box>
            <Typography className="new-articles-text2" variant="h1">
              <h1 className=" text-white font-Barlow">
                Transform Your
                <br />{" "}
                <span className=" text-[#7ac5ae] text-[40px] lg:text-[70px]">
                  Skin Today
                </span>
              </h1>
              <h1 className="text-[30px] lg:text-[50px] text-[#b7e081] font-Barlow">
                Book Appointment
              </h1>
            </Typography>
            <Box className="mt-[1.5rem] lg:w-[85%] text-left">
              <Typography className=" text-white ">
                <p className=" font-light font-Barlow text-sm lg:text-[20px]">
                  Remember that your skin type can affect how products work.
                  Using the wrong product, even when natural, can cause
                  breakouts, worsen blemishes, or cause redness.
                  <br /> It's best to find out what skin type you have and build
                  your skin care routine around that. You can also take notes on
                  product ingredients to see if specific ingredients are causing
                  unwanted skin reactions.
                </p>
              </Typography>
            </Box>
            <Box style={{ marginTop: "2rem" }}>
              <Typography className="new-articles-text2" variant="h1">
                <h1 className=" text-[#7ac5ae] font-Barlow">Opening Days</h1>
              </Typography>
              <Box style={{ marginTop: ".5rem" }}>
                <Grid container>
                  <Grid item>
                    <Box>
                      <Box>
                        <Typography className="small-body-text2">
                          <p className=" font-Barlow">Sunday-Saturday</p>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item>
                    <Typography className="small-body-text2">
                      <p className=" font-Barlow">8:00 AM - 6:00 PM</p>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            {/* <Box style={{ marginTop: "2rem" }}>
              <Typography className="new-articles-text2" variant="h1">
                Holiday - Saturday
              </Typography>
            </Box> */}
          </Box>
          {SuccessMessege === null ? null : (
            <Box className="successmessege">
              <Typography className="messegefonts">
                <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
                {SuccessMessege}
              </Typography>
            </Box>
          )}
          {ErrorMessege === null ? null : (
            <Box className="errormessege">
              <Typography className="messegefonts">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="messegeicon"
                />
                {ErrorMessege}
              </Typography>
            </Box>
          )}
        </Box>
      </Grid>
      <Grid item md={6} xs={12}>
        <Box className=" bg-gray-200 p-4 opacity-90 backdrop-blur-2xl rounded-tr-[36px] rounded-bl-[36px]    hover:opacity-100">
          <Box>
            <Typography variant="h1">
              <h3 className="font-Barlow text-[20px] lg:text-[30px] font-bold text-center ml-1.5">
                Book an <span className=" text-[#006d1b]">Appointment</span>
              </h3>
            </Typography>
          </Box>
          <Box style={{ marginTop: "2rem" }}>
            <Form onSubmit={handleSubmit}>
              <Grid container>
                <Controls.Input
                  name="full_name"
                  label="Full Name"
                  value={values.fullName}
                  onChange={handleInputChange}
                  error={errors.fullName}
                />

                <section className=" flex justify-between w-full">
                  <Controls.Input
                    label="Contact No."
                    name="contact_no"
                    value={values.contact_no}
                    onChange={handleInputChange}
                    error={errors.contact_no}
                  />
                  <Controls.Input
                    label="Email"
                    name="email"
                    value={values.email}
                    onChange={handleInputChange}
                    error={errors.email}
                  />
                </section>

                <Controls.Select
                  name="service_name"
                  label="Service Name"
                  value={values.service_name}
                  onChange={handleInputChange}
                  options={services}
                  error={errors.departmentId}
                />

                <Controls.DateTimePicker
                  name="date_and_time"
                  label="Date and Time"
                  value={values.date_and_time}
                  onChange={handleInputChange}
                />
                <Controls.Input
                  label="Message"
                  name="message"
                  value={values.message}
                  onChange={handleInputChange}
                  error={errors.message}
                />
                <Box style={{ marginTop: "12px" }}>
                  <Controls.Button
                    type="submit"
                    text="Make an Appointment"
                    backgroundColor="#1b284b"
                    onClick={handleSubmit}
                  />
                </Box>
              </Grid>
            </Form>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Appointment_Page;
