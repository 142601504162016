import { Box, Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import Footer from "../component/footer";
import Header from "../component/header";
import Landing_Page from "./landing";
import About_Page from "./about";
import Service_Page from "./services";
import Blog_Page from "./blog";
import Appointment_Page from "./appointment";
import Gallery_Page from "./gallery";
import Contact_Page from "./contact";

const Home = () => {
  return (
    <Box>
      <Header />
      <HomePage />
      <Footer />
    </Box>
  );
};

const HomePage = () => {
  return (
    <Box>
      <Box className="new-box-color">
        <Container>
          <Landing_Page />
        </Container>
      </Box>
      <Box>
        <Container>
          <About_Page />
        </Container>
      </Box>
      <Box className="new-res-margin1">
        <Box className="">
          <Container>
            <Service_Page />
          </Container>
        </Box>
      </Box>
      <Box>
        <Box>
          <Container>
            <Blog_Page />
          </Container>
        </Box>
      </Box>
      <Box>
        <Box className="appointment-images">
          <Box className="new-back-color">
            <Container>
              <Appointment_Page />
            </Container>
          </Box>
        </Box>
      </Box>
      <Box style={{ marginTop: "4rem" }}>
        <Box>
          <Container>
            <Gallery_Page />
          </Container>
        </Box>
      </Box>
      <Box style={{ marginTop: "4rem" }}>
        <Box className="appointment-images1">
          <Box className="new-back-color">
            <Container>
              <Contact_Page />
            </Container>
          </Box>
        </Box>
      </Box>
      <Box className="mb-3 lg:mb-[1rem]  news-letter">
        <Container>
          <Box className=" py-5 ">
            <Grid container spacing={7}>
              <Grid item xs={13} md={7}>
                <Box>
                  <Typography variant="h1" className="news-letter-text ">
                    Join Our Newsletter
                  </Typography>
                </Box>
                <Box>
                  <Typography className="small-body-text2">
                    <p className=" font-Barlow">
                      Please enter your Email to get Weekly updates.
                    </p>
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={5} xs={12}>
                <Box style={{ marginTop: "2.5rem" }}>
                  <Grid container className=" flex items-center font-poppins">
                    <Grid item xs={8}>
                      <input
                        type="text"
                        placeholder="Enter Your Email"
                        className="form-control"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <button className="subs-button px-3 py-2.5 bg-[#006d1b] text-white ">
                        Subscribe
                      </button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Home;
