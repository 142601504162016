import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/sidebar";
import { Grid, Typography } from "@mui/material";
import Header from "../../component/header";
import BasicTable from "../tables/BasicTable";
import {
  useGetHomePagesQuery,
  useUpdateHomePageMutation,
  useDeleteHomePageMutation,
  useAddHomePageMutation,
} from "../../../../services/homePageSlice";
import HomePageAddButton from "./homePageAddButton";
import { Box } from "@mui/system";

const DashboardHomePages = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <DashboardHomePagesPages />
      </Grid>
    </Grid>
  );
};

const DashboardHomePagesPages = () => {
  const { data, isLoading, isSuccess, isError, error } =
    useGetHomePagesQuery("getHomePages");

  const [deleteHomePage] = useDeleteHomePageMutation();

  const deleteConfirm = (e, id) => {
    e.preventDefault();
    console.log(id);
    deleteHomePage({ id });
  };

  let headers = [];
  let tableData = [];
  let content;
  if (isLoading) {
    content = <p>"Loading..."</p>;
  } else if (isSuccess) {
    headers = ["Title", "Sub-Title", "Active"];
    tableData = [];
    data.ids.forEach((key) => {
      tableData.push({
        Title: data.entities[key].title,
        "Sub-Title": data.entities[key].subtitle,
        id: data.entities[key].id,
        Active: data.entities[key].is_active,
      });
    });
  }
  return (
    <>
      <Box className="table-design-background">
        <Grid container>
          <Grid item xs={6}>
            <Typography className="dashboard-home-page-text">Home Page Details</Typography>
          </Grid>
          <Grid item xs={6}>
            <Link to="/dashboard/new/home-pages" style={{textDecoration:'none'}}>
              <HomePageAddButton />
            </Link>
          </Grid>
        </Grid>
        <Box style={{ padding: "1rem" }}>
          <BasicTable
            headers={headers}
            data={tableData}
            isDelete={true}
            deleteColor={"#f50057"}
            isEdit={true}
            editColor={"#3f51b5"}
            addColor={""}
            tableName={"home-pages"}
            deleteConfirm={deleteConfirm}
            activeSign={["Active"]}
          />
        </Box>
      </Box>
    </>
  );
};
export default DashboardHomePages;
