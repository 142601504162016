import { Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Footer from "../component/footer";
import Header from "../component/header";
import { FiHeart } from "react-icons/fi";
import { useGetProductsQuery } from "../../../services/productSlice";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Product_Header from "./productHeader";

const Product = () => {
  return (
    <Box>
      <Header />
      <ProductPage />
      <Footer />
    </Box>
  );
};

const ProductPage = () => {
  const { data, isLoading, isSuccess, isError, error } =
    useGetProductsQuery("getProducts");

  console.log(data);

  return (
    <Box>
      <Box className="new-box-color">
        <Container>
          <Product_Header />
        </Container>
      </Box>
      <Box style={{ marginTop: "4rem" }}>
        <Container>
          <Grid container spacing={3}>
            {!isSuccess ? (
              <Grid item md={3} xs={12}>
                <Box className="product-card-design">
                  <Box>
                    <SkeletonTheme color="grey" highlightColor="#444">
                      <p>
                        <Skeleton height={250} width={200} count={1} />
                      </p>
                    </SkeletonTheme>
                  </Box>
                  <Box>
                    <Skeleton />
                  </Box>
                  <Box>
                    <Skeleton count={3} />
                  </Box>
                </Box>
              </Grid>
            ) : (
              <>
                {data.ids.map((key) => (
                  <Grid item md={3} xs={12}>
                    <Box className="product-card-design">
                      <Box>
                        <img
                          src={data.entities[key].image}
                          className="logo-image-width"
                          alt="product-images"
                          height={250}
                        />
                      </Box>
                      <Box>
                        <FiHeart className="heart-icon-design" />
                      </Box>
                      <Box>
                        <Typography className="product-name">
                          {data.entities[key].name}
                        </Typography>
                        <Typography className="product-price">
                          {data.entities[key].description}
                        </Typography>
                        <Typography className="product-price1">
                          Rs. {data.entities[key].price}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </>
            )}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Product;
