import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const appointmentsAdapter = createEntityAdapter()

const initialState = appointmentsAdapter.getInitialState()

export const appointmentSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAppointments: builder.query({
            query: () => 'appointment/appointment/',
            transformResponse: responseData => {
                return appointmentsAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'Appointments', id: "LIST" },
                ...result.ids.map(id => ({ type: 'Appointments', id }))
            ]
        }),
        getActiveAppointments: builder.query({
            query: () => 'appointment/appointment/?is_confirm=True',
            transformResponse: responseData => {
                return appointmentsAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'Appointments', id: "LIST" },
                ...result.ids.map(id => ({ type: 'Appointments', id }))
            ]
        }),
        getAppointmentsById: builder.query({
            query: (id)=>({
                url:`appointment/appointment/${id}/`,
                method: 'GET',
            }),
            providesTags: ['Appointments']
        }),
        addAppointment: builder.mutation({
            query: (appointment) => {
            return{
                url: 'appointment/appointment/',
                method: 'POST',
                body: appointment
            }
        },
            invalidatesTags: ['Appointments']
        }),
        updateAppointment: builder.mutation({
           
            query: (updateAppointment) => {
                return{
                url: `appointment/appointment/${updateAppointment.get('id')}/`,
                method: 'PATCH',
                body: updateAppointment
                }
            },
            invalidatesTags: ['Appointments']
        }),
        deleteAppointment: builder.mutation({
            query: ({ id }) => {
                console.log(id);
                return{
                url: `appointment/appointment/${id}/`,
                method: 'DELETE',
                body: id
            }},
            invalidatesTags: ['Appointments']
        }),
    })
})

export const {
    useGetAppointmentsQuery,
    useGetActiveAppointmentsQuery,
    useAddAppointmentMutation,
    useUpdateAppointmentMutation,
    useDeleteAppointmentMutation,
    useGetAppointmentsByIdQuery
} = appointmentSlice

// returns the query result object
export const selectAppointmentsResult = appointmentSlice.endpoints.getAppointments.select()

// Creates memoized selector
const selectAppointmentssData = createSelector(
    selectAppointmentsResult,
    appointmentsResult => appointmentsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllAppointments,
    selectById: selectAppointmentById,
    selectIds: selectIds
    // Pass in a selector that returns the posts slice of state
} = appointmentsAdapter.getSelectors(state => selectAppointmentssData(state) ?? initialState)