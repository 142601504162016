import React from "react";
import { Grid } from "@mui/material";
import {Box} from "@mui/material";

const Five_Gallery = ({image}) => {


return(
    <>
<Grid item md={4} xs={12}>
    <Box style={{ marginBottom: "0px" }}>
      <img
        src={image[0]}
        className="images-hover-design-gallery"
        style={{ width: "100%", height: "300px" }}
      />
    </Box>
    <Box style={{ marginBottom: "0px" }}>
      <img
        src={image[1]}
        className="images-hover-design-gallery"
        style={{ width: "100%", height: "300px" }}
      />
    </Box>
  </Grid>
  <Grid item md={8} xs={12}>
    <Box style={{ marginBottom: "0px" }}>
      <img
        src={image[2]}
        className="images-hover-design-gallery"
        style={{ width: "100%", height: "600px" }}
      />
    </Box>
  </Grid>
  <Grid item md={6} xs={12}>
    <Box style={{ marginBottom: "0px" }}>
      <img
        src={image[3]}
        className="images-hover-design-gallery"
        style={{ width: "100%", height: "300px" }}
      />
    </Box>
  </Grid>
  <Grid item md={6} xs={12}>
    <Box style={{ marginBottom: "0px" }}>
      <img
        src={image[4]}
        className="images-hover-design-gallery"
        style={{ width: "100%", height: "300px" }}
      />
    </Box>
  </Grid>

</>
)

}

export default Five_Gallery;