import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/sidebar";
import { Grid, Typography } from "@mui/material";
import Header from "../../component/header";
import BasicTable from "../tables/BasicTable";
import {
  useGetProductsQuery,
  useUpdateProductMutation,
  useDeleteProductMutation,
  useAddProductMutation,
} from "../../../../services/productSlice";
import ProductAddButton from "./productAddButton";
import { Box } from "@mui/system";

const DashboardProducts = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <DashboardProductsPages />
      </Grid>
    </Grid>
  );
};

const DashboardProductsPages = () => {
  const { data, isLoading, isSuccess, isError, error } =
    useGetProductsQuery("getProducts");

  const [deleteProduct] = useDeleteProductMutation();

  const deleteConfirm = (e, id) => {
    e.preventDefault();
    console.log(id);
    deleteProduct({ id });
  };

  let headers = [];
  let tableData = [];
  let content;
  if (isLoading) {
    content = <p>"Loading..."</p>;
  } else if (isSuccess) {
    headers = ["Product Name", "Description", "Price"];
    tableData = [];
    data.ids.forEach((key) => {
      tableData.push({
        "Product Name": data.entities[key].name,
        Description: data.entities[key].description,
        Price: data.entities[key].price,
        id: data.entities[key].id,
      });
    });
  }
  return (
    <>
      <Box className="table-design-background">
        <Grid container>
          <Grid item xs={6}>
            <Typography className="dashboard-home-page-text">
              Product Lists
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Link
              to="/dashboard/new/products"
              style={{ textDecoration: "none" }}
            >
              <ProductAddButton />
            </Link>
          </Grid>
        </Grid>
        <Box style={{ padding: "1.5rem" }}>
          <BasicTable
            headers={headers}
            data={tableData}
            isDelete={true}
            deleteColor={"#f50057"}
            isEdit={true}
            editColor={"#3f51b5"}
            addColor={""}
            tableName={"products"}
            deleteConfirm={deleteConfirm}
          />
        </Box>
      </Box>
    </>
  );
};
export default DashboardProducts;
