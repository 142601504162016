import { Grid, List, li, Modal, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../../static/images/logo.gif";
import { BsSearch, BsChevronDown } from "react-icons/bs";
import SearchBar from "material-ui-search-bar";
import { useGetServicesQuery } from "../../../services/serviceSlice";
import { FaBars } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { IoMdArrowDropdown } from "react-icons/io";
import { HashLink } from "react-router-hash-link";
import ScrollToTop from "./scrollTop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faLocation,
  faLocationPin,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faViber, faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Header = () => {
  const { data, isLoading, isSuccess, isError, error } =
    useGetServicesQuery("getServices");
  console.log(data);
  if (isLoading) {
    console.log("not happening");
  } else if (isSuccess) {
    console.log(data);
  }
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [Show, setShow] = useState(false);

  return (
    <Box>
      <SubHeader />
      <Box
        className="res-nav-bar"
        style={{ backgroundColor: "rgb(233, 254, 204)" }}
      >
        <Container>
          <Box className="nav-bar-padding">
            <Grid container spacing={3}>
              <Grid item xs={2}>
                <div className="lg:w-[150px] xl:w-[300px] lg:h-[113px] xl:h-[117px] bg-[#e9fecc] absolute left-0 top-4  skewed-border-right"></div>
                <Box className="logoimg" style={{ marginTop: "-40px" }}>
                  <img
                    src={Logo}
                    alt="logo-png"
                    className="w-[50%] lg:w-[12%] xl:w-[8%]  rounded-full absolute lg:left-[5px] xl:left-[9%] lg:top-[16px] xl:top-[16px]"
                  />
                </Box>
              </Grid>
              {/* <Grid item xs={1}></Grid> */}
              <Grid item xs={10} style={{ alignContent: "center" }}>
                <Box>
                  <List
                    style={{
                      textAlign: "start",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    className="list-bar"
                  >
                    <li className="nav-link-bar">
                      <Link to="/" className="nav-link-bar-text">
                        Home
                      </Link>
                    </li>
                    <li className="nav-link-bar1">
                      <HashLink to="/#about_us" className="nav-link-bar-text1">
                        About Us
                      </HashLink>
                    </li>
                    <li className="nav-link-bar1 drop-down">
                      <Link to="/" className="nav-link-bar-text1">
                        Our Service
                        <BsChevronDown
                          style={{
                            position: "relative",
                            left: "4px",
                            fontWeight: "700",
                            top: "3px",
                          }}
                        />
                      </Link>
                      <Box className="drop-down-list">
                        <Grid container>
                          {data ? (
                            <>
                              {data.ids.map((key) => (
                                <Grid item xs={6}>
                                  <Link
                                    to={`/services/${data.entities[key].id}`}
                                    key={key}
                                    className="my-dropdown-list-items"
                                  >
                                    {data.entities[key].service_name}
                                  </Link>
                                </Grid>
                              ))}
                            </>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Box>
                    </li>
                    <li className="nav-link-bar1">
                      <Link to="/doctor" className="nav-link-bar-text1">
                        Our Doctor
                      </Link>
                    </li>
                    <li className="nav-link-bar1">
                      <HashLink to="/blog" className="nav-link-bar-text1">
                        Blog
                      </HashLink>
                    </li>
                    <li className="nav-link-bar1 btn-custom">
                      <HashLink
                        to="/#appointment"
                        className="nav-link-bar-text1"
                      >
                        Book Appointment
                      </HashLink>
                    </li>
                  </List>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box className="display-nav-bar">
        <Grid container>
          <Grid item xs={3}>
            <Link to="/">
              <img
                src={Logo}
                style={{ width: "60%" }}
                className="res-margin-logo rounded-full"
                alt="logo"
              />
            </Link>
          </Grid>
          <Grid
            item
            xs={9}
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Box style={{ textAlign: "right", marginTop: "25px" }}>
              <FaBars size={40} onClick={handleOpen} />
            </Box>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              // data-aos="fade-left"
              // data-aos-anchor="#example-anchor"
              // data-aos-offset="500"
              // data-aos-duration="500"
            >
              <Box className="modal-design">
                <Box style={{ textAlign: "right" }}>
                  <AiOutlineClose
                    onClick={handleClose}
                    size={34}
                    color="#f5365b"
                  />
                </Box>
                <Box style={{ marginTop: "1rem" }}>
                  <Link to="/" className="res-nav-link">
                    Home
                  </Link>
                  <Link to="/" className="res-nav-link">
                    About Us
                  </Link>
                  <Link
                    to="/"
                    className="res-nav-link1"
                    onClick={
                      !Show === true
                        ? () => setShow(true)
                        : () => setShow(false)
                    }
                  >
                    <Grid container>
                      <Grid item xs={11}>
                        Services
                      </Grid>
                      <Grid item xs={1}>
                        <Box style={{ textAlign: "end" }}>
                          <IoMdArrowDropdown />
                        </Box>
                      </Grid>
                    </Grid>
                  </Link>
                  {Show === true ? (
                    <>
                      {data ? (
                        <>
                          {data.ids.map((key) => (
                            <Link
                              to={`services/${data.entities[key].id}`}
                              key={key}
                              className="res-nav-link res-nav-link2"
                            >
                              {data.entities[key].service_name}
                            </Link>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : null}
                  <Link to="/" className="res-nav-link">
                    Book Appointment
                  </Link>
                  <Link to="/product" className="res-nav-link">
                    Our Products
                  </Link>
                  <Link to="/blog" className="res-nav-link">
                    Blog
                  </Link>
                </Box>
              </Box>
            </Modal>
          </Grid>
        </Grid>
      </Box>
      <ScrollToTop />
    </Box>
  );
};

const SubHeader = () => {
  return (
    <div className="top-header p-2">
      <Container>
        <div className="  flex justify-center lg:justify-end gap-5">
          <div>
            <p className="font-Barlow text-[7px]  md:text-xs lg:text-base text-white font-semibold">
              <FontAwesomeIcon className="mr-2" icon={faPhoneAlt} />
              091-520707 | 091-527142
            </p>
          </div>
          <div item>
            <p className="font-Barlow text-white font-semibold text-[7px] md:text-xs lg:text-base">
              <FontAwesomeIcon className="mr-2" icon={faLocationPin} />
              Chatakpur , Dhangadhi, Nepal
            </p>
          </div>

          <div item>
            <div>
              <p className="font-Barlow text-white font-semibold text-[7px] md:text-xs lg:text-base">
                <FontAwesomeIcon className="mr-2" icon={faEnvelope} />
                dhangadhiskincare@gmail.com
              </p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default Header;
