import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { config } from "@fortawesome/fontawesome-svg-core";
config.autoAddCss = false;

export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 350) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <>
      <Box
        style={{
          position: "fixed",
          bottom: 50,
          right: 51,
          zIndex: 999,
        }}
      >
        {isVisible && (
          <button
            className="btn btn-primary btn-small"
            onClick={scrollToTop}
            style={{
              backgroundColor: "#006d1b",
              border: "none",
              borderRadius: "5px",
              padding: "5px",
              paddingRight: "13px",
              paddingLeft: "13px",
            }}
          >
            <FontAwesomeIcon style={{ color: "white" }} icon={faArrowUp} />
          </button>
        )}
      </Box>
    </>
  );
}
