import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import {
  HiArrowNarrowRight,
  HiOutlineMail,
  HiOutlineLocationMarker,
} from "react-icons/hi";
import { TbVideo, TbUser } from "react-icons/tb";
import Skin from "../../../static/images/skin.png";
import { useGetActiveHomePagesQuery } from "../../../services/homePageSlice";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Images1 from "../../../static/images/aboutimages.jpeg";
import New from "../../../static/images/new.jpeg";
import Divider from "../../../static/images/divider.png";
import { useGetActiveAboutPagesQuery } from "../../../services/aboutPageSlice";
import { HashLink } from "react-router-hash-link";

const About_Page = () => {
  const { data, isLoading, isSuccess, isError, error } =
    useGetActiveAboutPagesQuery("getActiveAboutPages");

  return (
    <Box className="res-margin" id="about_us">
      <Grid container spacing={5}>
        <Grid item md={6} xs={12}>
          <Grid container className="About-Cont">
            <Grid item xs={8}>
              {data === undefined ? (
                <SkeletonTheme color="grey" highlightColor="#444">
                  <p>
                    <Skeleton height={300} width={370} count={1} />
                  </p>
                </SkeletonTheme>
              ) : (
                <>
                  {data.ids.length === 0 ? (
                    <SkeletonTheme color="grey" highlightColor="#444">
                      <p>
                        <Skeleton height={250} width={300} count={1} />
                      </p>
                    </SkeletonTheme>
                  ) : (
                    <img
                      src={data.entities[data.ids[0]].image1}
                      className=" rounded-tr-[40px] rounded-bl-[40px]"
                      data-aos="fade-down"
                      data-aos-duration="1000"
                    />
                  )}
                </>
              )}
            </Grid>
            <Grid item xs={8}>
              <Box className="new-position-images">
                {data === undefined ? (
                  <SkeletonTheme color="grey" highlightColor="#444">
                    <p>
                      <Skeleton height={300} width={370} count={1} />
                    </p>
                  </SkeletonTheme>
                ) : (
                  <>
                    {data.ids.length === 0 ? (
                      <SkeletonTheme color="grey" highlightColor="#444">
                        <p>
                          <Skeleton height={250} width={300} count={1} />
                        </p>
                      </SkeletonTheme>
                    ) : (
                      <img
                        src={data.entities[data.ids[0]].image2}
                        className="rounded-tl-[40px] rounded-br-[40px]"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                      />
                    )}
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box
            className="new-res-margin"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <Box>
              {data === undefined ? (
                <Typography className="text-fonts">
                  <Skeleton />
                </Typography>
              ) : (
                <>
                  {data.ids.length === 0 ? (
                    <Typography className="text-fonts">
                      <Skeleton />
                    </Typography>
                  ) : (
                    <Typography className="text-fonts">
                      {data.entities[data.ids[0]].title}
                    </Typography>
                  )}
                </>
              )}
            </Box>
            <Box style={{ marginTop: "0.5rem" }}>
              {data === undefined ? (
                <Typography variant="h1" className="body-font-size">
                  <Skeleton count={2} />
                </Typography>
              ) : (
                <>
                  {data.ids.length === 0 ? (
                    <Typography variant="h1" className="body-font-size">
                      <Skeleton />
                    </Typography>
                  ) : (
                    <Typography variant="h1" className="body-font-size">
                      {data.entities[data.ids[0]].subtitle}
                    </Typography>
                  )}
                </>
              )}
            </Box>
            <Box
              style={{ marginTop: "0.5rem", textAlign: "start" }}
              className="curve-rope"
            >
              <img src={Divider} />
            </Box>
            <Box>
              {data === undefined ? (
                <Typography className="small-body-text">
                  <Skeleton count={3} />
                </Typography>
              ) : (
                <>
                  {data.ids.length === 0 ? (
                    <Typography className="small-body-text">
                      <Skeleton />
                    </Typography>
                  ) : (
                    <Typography className="small-body-text">
                      {data.entities[data.ids[0]].description}
                    </Typography>
                  )}
                </>
              )}
            </Box>
            <Box style={{ marginTop: "1.5rem" }}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Box>
                    {data === undefined ? (
                      <Typography variant="h1" className="data-fonts">
                        <Skeleton />
                      </Typography>
                    ) : (
                      <>
                        {data.ids.length === 0 ? (
                          <Typography variant="h1" className="data-fonts">
                            <Skeleton />
                          </Typography>
                        ) : (
                          <Typography variant="h1" className="data-fonts">
                            {data.entities[data.ids[0]].random1}
                          </Typography>
                        )}
                      </>
                    )}
                  </Box>
                  <Box>
                    <Typography className="video-icons-text1">
                      Happy Clients
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box>
                    {data === undefined ? (
                      <Typography variant="h1" className="data-fonts">
                        <Skeleton />
                      </Typography>
                    ) : (
                      <>
                        {data.ids.length === 0 ? (
                          <Typography variant="h1" className="data-fonts">
                            <Skeleton />
                          </Typography>
                        ) : (
                          <Typography variant="h1" className="data-fonts">
                            {data.entities[data.ids[0]].random2}
                          </Typography>
                        )}
                      </>
                    )}
                  </Box>
                  <Box>
                    <Typography className="video-icons-text1">
                      Clinics
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box>
                    {data === undefined ? (
                      <Typography variant="h1" className="data-fonts">
                        <Skeleton />
                      </Typography>
                    ) : (
                      <>
                        {data.ids.length === 0 ? (
                          <Typography variant="h1" className="data-fonts">
                            <Skeleton />
                          </Typography>
                        ) : (
                          <Typography variant="h1" className="data-fonts">
                            {data.entities[data.ids[0]].random3}
                          </Typography>
                        )}
                      </>
                    )}
                  </Box>
                  <Box>
                    <Typography className="video-icons-text1">
                      Awards
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box style={{ marginTop: "3rem" }}>
              <Grid item md={5} xs={7}>
                <Box>
                  <HashLink to="/#appointment">
                    <button className="contact-us-button">
                      Booking Appointment{" "}
                      <HiArrowNarrowRight className="contact-us-button-icon" />
                    </button>
                  </HashLink>
                </Box>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default About_Page;
