import { Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import Footer from "../component/footer";
import Header from "../component/header";
import { FaChevronRight } from "react-icons/fa";
import Skin from "../../../static/images/skin.png";
import { useParams } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useGetServicesByIdQuery } from "../../../services/serviceSlice";

const ServicesDetailPage = () => {
  return (
    <Box>
      <Header />
      <ServicesPage />
      <Footer />
    </Box>
  );
};

const ServicesPage = () => {
  const { id } = useParams();
  const { data } = useGetServicesByIdQuery(id);

  return (
    <>
      {data ? (
        <Box>
          <Box className="new-box-color">
            <Container>
              <Grid container spacing={7}>
                <Grid item md={7} xs={12}>
                  <Box style={{ marginTop: "5rem" }}>
                    <Typography variant="h1" className="body-h1-text">
                      {data.service_name}
                    </Typography>
                    <Box style={{ marginTop: "2rem", textAlign: "start" }}>
                      <span className="flex">
                        <Link to="/" className="footer-service-link1">
                          Home
                        </Link>
                        <FaChevronRight
                          style={{
                            marginLeft: "15px",
                            marginRight: "15px",
                            marginTop: "5px",
                          }}
                          size={12}
                        />
                        <Link to="/" className="footer-service-link1">
                          {data.service_name}
                        </Link>
                      </span>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={1} xs={0}></Grid>
                <Grid item md={4} xs={12}>
                  <img
                    src={data.image}
                    className="logo-image-width image-margin"
                    alt="service-images"
                  />
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Box className="blog-detail-margin">
            <Container>
              <Box>
                {/* <Typography className="detail-page-fonts1">
              Lorem ipsum dolor sit amet, at eos re pudiare ad versa ium. E um
              doming pericula eu, harum simul scaevola cu sit, vim an so net la
              oreet. Aperiam ato morum at pri. In pro la bore con tentiones medi
              ocritat em, mea et perci pit interes set, mei et vocibus elige di
              vivend um.
            </Typography> */}
                <Box style={{ marginTop: "2.5rem" }}>
                  <Typography className="detail-page-fonts">
                    {data.description}
                  </Typography>
                </Box>
              </Box>
            </Container>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box className="new-box-color">
            <Container>
              <Grid container spacing={7}>
                <Grid item xs={7}>
                  <Box style={{ marginTop: "5rem" }}>
                    <Skeleton />
                    <Box style={{ marginTop: "2rem", textAlign: "start" }}>
                      <Skeleton />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={4}>
                  <SkeletonTheme color="grey" highlightColor="#444">
                    <p>
                      <Skeleton height={250} width={300} count={1} />
                    </p>
                  </SkeletonTheme>
                </Grid>
              </Grid>
            </Container>
          </Box>
          <Box style={{ marginTop: "4.5rem", textAlign: "start" }}>
            <Container>
              <Box>
                <Box style={{ marginTop: "2.5rem" }}>
                  <Skeleton />
                </Box>
              </Box>
            </Container>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ServicesDetailPage;
