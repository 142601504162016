import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const contactsAdapter = createEntityAdapter()

const initialState = contactsAdapter.getInitialState()

export const contactSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getContacts: builder.query({
            query: () => 'basic/contact/',
            transformResponse: responseData => {
                return contactsAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'Contacts', id: "LIST" },
                ...result.ids.map(id => ({ type: 'Contacts', id }))
            ]
        }),
        getActiveContacts: builder.query({
            query: () => 'basic/contact/?is_confirm=True',
            transformResponse: responseData => {
                return contactsAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'Contacts', id: "LIST" },
                ...result.ids.map(id => ({ type: 'Contacts', id }))
            ]
        }),
        getContactsById: builder.query({
            query: (id)=>({
                url:`basic/contact/${id}/`,
                method: 'GET',
            }),
            providesTags: ['Contacts']
        }),
        addContact: builder.mutation({
            query: (contact) => {
            return{
                url: 'basic/contact/',
                method: 'POST',
                body: contact
            }
        },
            invalidatesTags: ['Contacts']
        }),
        updateContact: builder.mutation({
           
            query: (updateContact) => {
                return{
                url: `basic/contact/${updateContact.get('id')}/`,
                method: 'PATCH',
                body: updateContact
                }
            },
            invalidatesTags: ['Contacts']
        }),
        deleteContact: builder.mutation({
            query: ({ id }) => {
                console.log(id);
                return{
                url: `basic/contact/${id}/`,
                method: 'DELETE',
                body: id
            }},
            invalidatesTags: ['Contacts']
        }),
    })
})

export const {
    useGetContactsQuery,
    useGetActiveContactsQuery,
    useAddContactMutation,
    useUpdateContactMutation,
    useDeleteContactMutation,
    useGetContactsByIdQuery
} = contactSlice

// returns the query result object
export const selectContactsResult = contactSlice.endpoints.getContacts.select()

// Creates memoized selector
const selectContactssData = createSelector(
    selectContactsResult,
    contactsResult => contactsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllContacts,
    selectById: selectContactById,
    selectIds: selectIds
    // Pass in a selector that returns the posts slice of state
} = contactsAdapter.getSelectors(state => selectContactssData(state) ?? initialState)