export function isFile(input) {
    console.log("here")
    console.log(input);
    if ('File' in window && input instanceof File)
       return true;
    else return false;
 }
 
export function isBlob(input) {
    console.log(input);
    console.log(input instanceof Blob);
     if ('Blob' in window && input instanceof Blob)
         return true;
     else return false;
 }