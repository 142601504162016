import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/sidebar";
import { Grid, Pagination, Stack, Typography } from "@mui/material";
import Header from "../../component/header";
import BasicTable from "../tables/BasicTable";
import {
  useGetAppointmentsQuery,
  useUpdateAppointmentMutation,
  useDeleteAppointmentMutation,
  useAddAppointmentMutation,
} from "../../../../services/appointmentSlice";
import AppointmentAddButton from "./appointmentAddButton";
import { Box } from "@mui/system";
import usePagination from "../../component/pagination";

const DashboardAppointments = () => {
  return <DashboardAppointmentsPages />;
};

const DashboardAppointmentsPages = () => {
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  const { data, isLoading, isSuccess, isError, error } =
    useGetAppointmentsQuery("getAppointments");

  const [deleteAppointment] = useDeleteAppointmentMutation();

  const deleteConfirm = (e, id) => {
    e.preventDefault();
    console.log(id);
    deleteAppointment({ id });
  };

  let headers = [];
  let tableData = [];
  let content;
  if (isLoading) {
    content = <p>"Loading..."</p>;
  } else if (isSuccess) {
    headers = [
      "Full Name",
      "Email",
      "Contact No",
      "Date & Time",
      "Is Confirmed",
      "Service Name",
    ];
    tableData = [];
    data.ids.forEach((key) => {
      tableData.push({
        "Full Name": data.entities[key].full_name,
        Email: data.entities[key].email,
        id: data.entities[key].id,
        "Is Confirmed": data.entities[key].is_confirm,
        "Contact No": data.entities[key].contact_no,
        "Service Name": data.entities[key].service_name.service_name,
        "Date & Time": formatDate(data.entities[key].date_and_time),
      });
    });
  }
  const [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const count = Math.ceil(tableData.length / PER_PAGE);
  const _DATA = usePagination(tableData, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  return (
    <>
      <Box className="table-design-background">
        <Grid container>
          <Grid item xs={6}>
            <Typography className="dashboard-home-page-text">
              Appointment Page Details
            </Typography>
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
        <Box style={{ padding: "1rem" }}>
          <BasicTable
            headers={headers}
            data={_DATA.currentData()}
            isDelete={true}
            deleteColor={"#f50057"}
            isEdit={true}
            editColor={"#3f51b5"}
            addColor={""}
            tableName={"appointment"}
            deleteConfirm={deleteConfirm}
            activeSign={["Is Confirmed"]}
          />
          <Box style={{ marginTop: "20px", textAlign: "end" }}>
            <Stack spacing={2}>
              <Pagination
                count={count}
                size="large"
                page={page}
                variant="outlined"
                onChange={handleChange}
              />
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default DashboardAppointments;
