import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";
import { useGetAppointmentsByIdQuery } from "../../../../services/appointmentSlice";
import BlobToFile from "../forms/utils/blobToFile";
import { useUpdateAppointmentMutation } from "../../../../services/appointmentSlice";
import urlToFile from "../forms/utils/urlToFile";
import { useGetServicesQuery } from "../../../../services/serviceSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBell,
  faSignInAlt,
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

const AppointmentEditPage = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <EditAppointmentPages />
      </Grid>
    </Grid>
  );
};

const EditAppointmentPages = () => {
  const { id } = useParams();
  const { data, isLoading, isSuccess, isError, error } =
    useGetServicesQuery("getServices");
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);

  const services = [];
  if (isSuccess) {
    data.ids.forEach((key) => {
      console.log(data[key]);
      services.push({ id: key, title: data.entities[key].service_name });
    });
  }
  const Appointments = useGetAppointmentsByIdQuery(id);
  const [File, setFile] = useState([]);
  const [updateAppointment, setUpdateAppointment] = useState([]);
  const [initialFValues, setInitialFValues] = useState({
    full_name: "",
    is_confirm: "false",
    contact_no: "",
    email: "",
    message: "",
    date_and_time: "",
    service_name: "",
  });

  useEffect(() => {
    if (Appointments.data) {
      var date = new Date(Appointments.data.date_and_time);
      console.log(date);
      setInitialFValues({
        is_confirm: Appointments.data.is_confirm.toString(),
        full_name: Appointments.data.full_name,
        contact_no: Appointments.data.contact_no,
        email: Appointments.data.email,
        message: Appointments.data.message,
        date_and_time: date,
        service_name: Appointments.data.service_name.id,
      });
    }
  }, [Appointments.data]);

  const { values, handleImageUpload, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    true
  );

  const [editAppointment] = useUpdateAppointmentMutation();

  console.log(values);
  const history = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if(values.is_confirm)
      formData.append("is_confirm", values.is_confirm);
    if(values.service_name)
      formData.append("service_name", values.service_name);
    if(values.date_and_time)
      formData.append("date_and_time", values.date_and_time.toISOString());
    formData.append("id", id);
    editAppointment(formData)
      .then((res) => {
        setSuccesMessege("Successfully Updated Appointment.");
        setTimeout(() => {
          history("/dashboard/home");
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur. Please Try Again Later.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <Box className="table-design-background">
      <Typography className="dashboard-home-page-text">
        Update Appointment Page
      </Typography>
      <Box style={{ padding: "1.5rem" }}>
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={6} style={{ paddingLeft: "1.5rem" }}>
              <Grid container spacing={3}>
                <Grid>
                  <Box className="full-name-design">
                    <Typography className="full-name-font">
                      Full Name: {values.full_name}
                    </Typography>
                  </Box>
                </Grid>
                <Grid>
                  <Box className="full-name-design">
                    <Typography className="full-name-font">
                      Contact No: {values.contact_no}
                    </Typography>
                  </Box>
                </Grid>
                <Grid>
                  <Box className="full-name-design">
                    <Typography className="full-name-font">
                      Email: {values.email}
                    </Typography>
                  </Box>
                </Grid>
                <Grid>
                  <Box className="full-name-design">
                    <Typography className="full-name-font">
                      Message: {values.message}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Controls.Select
                name="service_name"
                label="Service Name"
                value={values.service_name}
                onChange={handleInputChange}
                options={services}
              />
              <Box style={{ marginTop: "10px" }}>
                <Controls.DateTimePicker
                  name="date_and_time"
                  label="Date and Time"
                  value={values.date_and_time}
                  onChange={handleInputChange}
                />
              </Box>
              <Controls.RadioGroup
                name="is_confirm"
                label="Is Confirmed"
                value={values.is_confirm}
                onChange={handleInputChange}
                items={[
                  { id: "false", title: "False" },
                  { id: "true", title: "True" },
                ]}
              />
            </Grid>
            <Grid style={{ marginTop: "12px" }}>
              <Controls.Button
                type="submit"
                text="Submit"
                onClick={handleSubmit}
                backgroundColor="#1b284b"
              />
            </Grid>
          </Grid>
        </Form>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AppointmentEditPage;
