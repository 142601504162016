import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";
import { useGetProductsByIdQuery } from "../../../../services/productSlice";
import BlobToFile from "../forms/utils/blobToFile";
import { useUpdateProductMutation } from "../../../../services/productSlice";
import urlToFile from "../forms/utils/urlToFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBell,
  faSignInAlt,
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const ProductEditPage = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <EditProductPages />
      </Grid>
    </Grid>
  );
};

const EditProductPages = () => {
  const { id } = useParams();
  const Products = useGetProductsByIdQuery(id);
  const [File, setFile] = useState([]);
  const [updateProduct, setUpdateProduct] = useState([]);
  const [initialFValues, setInitialFValues] = useState({
    image: [],
    name: "",
    price: 0,
    description: "",
  });
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);
  const history = useNavigate();

  useEffect(() => {
    if (Products.data) {
      urlToFile(Products.data.image, setFile);
      setInitialFValues({
        image: File,
        name: Products.data.name,
        price: Products.data.price,
        description: Products.data.description,
      });
    }
  }, [Products.data]);

  const { values, handleImageUpload, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    true
  );

  const [editProduct] = useUpdateProductMutation();

  useEffect(() => {
    handleImageUpload("image", File);
  }, [File]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    var file = BlobToFile(values.image, "product");
    if (file.length !== 0) {
      formData.append("image", file, "product.jpg");
    }
    if (values.name)
      formData.append("name", values.name);
    if (values.price)
      formData.append("price", values.price);
    if (values.description)
      formData.append("description", values.description);
    formData.append("id", id);
    editProduct(formData)
      .then((res) => {
        setSuccesMessege("Successfully Update Product Data.");
        setTimeout(() => {
          history("/dashboard/products");
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur. Please Try Again Later.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <Box className="table-design-background">
      <Typography className="dashboard-home-page-text">
        Update Product List
      </Typography>
      <Box style={{ padding: "1.5rem" }}>
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={6}>
              <Controls.Input
                name="name"
                label="Product Name"
                value={values.name}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="price"
                label="Price"
                value={values.price}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="description"
                label="Description"
                value={values.description}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Image setFile={setFile} />
            </Grid>
            <Grid style={{ marginTop: "12px" }}>
              <Controls.Button
                type="submit"
                text="Submit"
                backgroundColor="#1b284b"
                onClick={handleSubmit}
              />
            </Grid>
          </Grid>
        </Form>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ProductEditPage;
