import React from "react";
import {
  Box,
  Button,
  Grid,
  Grow,
  MenuItem,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import Divider from "../../../static/images/divider.png";
import Articles from "../../../static/images/articles.jpeg";
import { useGetActiveImageGallerysQuery } from "../../../services/imageGallerySlice";
import Reusable_Gallery from "./galleryComponents/reusableGallery";

const Gallery_Page = () => {
  const { data, isLoading, isSuccess, isError, error } =
    useGetActiveImageGallerysQuery("getImageGallerys");

  console.log(data);

  var count = 1;
  const dataArray = [];

  if (isSuccess) {
    data.ids.forEach((key) => {
      dataArray.push(data.entities[key].image);
      count++;
    });
  }

  return (
    <>
      <Grid container>
        <Grid xs={12} md={7} style={{ margin: "0 auto" }}>
          <Box>
            <Box>
              <Typography className="text-fonts1">Latest Gallery</Typography>
            </Box>
            <Box>
              <Typography variant="h1" className="body-font-size1">
                <p className=" text-[28px] md:text-[51px] lg:text-[58px]">
                  Our Photo Gallery
                </p>
              </Typography>
            </Box>
            <Box
              style={{ marginTop: "1rem", marginLeft: "18rem" }}
              className="curve-rope"
            >
              <img src={Divider} />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box className="gallery-margin-top">
        <Grid container spacing={3}>
          <Reusable_Gallery dataArray={dataArray} />
        </Grid>
      </Box>
    </>
  );
};

export default Gallery_Page;
