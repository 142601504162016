import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { HiArrowNarrowRight } from "react-icons/hi";
import { TbVideo } from "react-icons/tb";
import { useGetActiveHomePagesQuery } from "../../../services/homePageSlice";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Images from "../../../static/images/articles.jpeg";
import { useGetServicesQuery } from "../../../services/serviceSlice";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { Carousel } from "react-bootstrap";
import ReactCurvedText from "react-curved-text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo } from "@fortawesome/free-solid-svg-icons";

const Landing_Page = () => {
  const { data, isLoading, isSuccess, isError, error } =
    useGetActiveHomePagesQuery("getActiveHomePages");
  if (data !== undefined) {
    console.log(data.entities.length);
  }

  const Services = useGetServicesQuery();

  return (
    <Box>
      <Grid container spacing={7}>
        <Grid item md={6} xs={12} className="display-on">
          <Box data-aos="fade-right" data-aos-duration="1000">
            {data === undefined ? (
              <Typography variant="h1" className="body-h1-text">
                <Skeleton count={3} />
              </Typography>
            ) : (
              <>
                {data.ids.length === 0 ? (
                  <Typography variant="h1" className="body-h1-text">
                    <Skeleton count={3} />
                  </Typography>
                ) : (
                  <Typography variant="h1" className="body-h1-text">
                    <p className=" text-[35px] lg:text-[85px] leading-tight text-left">
                      {data.entities[data.ids[0]].title}
                    </p>
                  </Typography>
                )}
              </>
            )}
          </Box>
          <Box>
            {data === undefined ? (
              <Typography className="small-body-text">
                <Skeleton count={3} />
              </Typography>
            ) : (
              <>
                {
                  data.ids.length === 0 ? (
                    <Typography className="small-body-text">
                      <Skeleton count={3} />
                    </Typography>
                  ) : (
                    <></>
                  )
                  //   <Typography className="small-body-text">
                  //   {data.entities[data.ids[0]].subtitle}
                  // </Typography>
                }
              </>
            )}
          </Box>
          <Box
            style={{ paddingTop: "5rem", marginLeft: "25px" }}
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <Grid container spacing={3}>
              <Grid item md={4} xs={12}>
                <Box>
                  <button className="contact-us-button">
                    Contact Us{" "}
                    <HiArrowNarrowRight className="contact-us-button-icon" />
                  </button>
                </Box>
              </Grid>
              <Grid item md={4} xs={12} className="res-nav-bar">
                <Grid container spacing={0}>
                  <Grid xs={4} item>
                    <FontAwesomeIcon icon={faVideo} className="video-icons" />
                  </Grid>
                  <Grid xs={8} item>
                    <Typography className="video-icons-text">
                      {" "}
                      Watch Video
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Grid container spacing={3}>
            <Grid item xs={5}></Grid>
            <Grid item xs={7}>
              <Box>
                {Services.status !== "fulfilled" ? (
                  // <SkeletonTheme color="grey" highlightColor="#444" >
                  //   <p className="carasoul-margin res-nav-bar">
                  //     <Skeleton className="images-design-slider" count={1} />
                  //   </p>
                  // </SkeletonTheme>
                  <></>
                ) : (
                  <>
                    <Carousel
                      className="carasoul-margin res-nav-bar"
                      fade
                      nextIcon={false}
                      prevIcon={false}
                      indicators={false}
                    >
                      {Services.data.ids.map((key) => (
                        <Carousel.Item interval={3000}>
                          <img
                            src={Services.data.entities[key].image}
                            className="images-design-slider"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                          />
                          {/* <h1 className="new-image-fontSize">
                            {Services.data.entities[key].service_name}
                          </h1> */}
                        </Carousel.Item>
                      ))}
                    </Carousel>
                    {/* <div
                      id="carouselExampleControls"
                      class="carousel slide"
                      data-bs-ride="carousel"
                    >
                      {Services.data.ids.map((key) => (
                        <div class="carousel-inner">
                          <div class="carousel-item active">
                            <img src="..." class="d-block w-100" alt="..." />
                          </div>
                          <div class="carousel-item">
                            <img src="..." class="d-block w-100" alt="..." />
                          </div>
                        </div>
                      ))}
                    </div> */}
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={1}></Grid>
        <Grid item md={5} xs={12} className="mobile-device">
          {data === undefined ? (
            <SkeletonTheme color="grey" highlightColor="#444">
              <p>
                <Skeleton height={447} width={447} count={1} />
              </p>
            </SkeletonTheme>
          ) : (
            <>
              {data.ids.length === 0 ? (
                <SkeletonTheme color="grey" highlightColor="#444">
                  <p>
                    <Skeleton height={250} width={300} count={1} />
                  </p>
                </SkeletonTheme>
              ) : (
                <img
                  src={data.entities[data.ids[0]].image}
                  className=" h-[200px] lg:h-full rounded-tr-[40px] rounded-bl-[40px] mx-auto lg:mx-0"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                />
              )}
            </>
          )}
          {/* <Box className="my-box-design res-nav-bar"></Box> */}
          {/* <Box className="my-box-design1 res-nav-bar"></Box> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Landing_Page;
