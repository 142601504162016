import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { HiOutlineMail, HiOutlineLocationMarker } from "react-icons/hi";
import { FiPhoneCall } from "react-icons/fi";
import Controls from "../../dashboard/pages/forms/controls/Controls";
import { useForm, Form } from "../../dashboard/pages/forms/useForm";
import { useAddContactMutation } from "../../../services/contactSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBell,
  faSignInAlt,
  faExclamationTriangle,
  faCheckCircle,
  faPhone,
  faEnvelope,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";

const Contact_Page = () => {
  const initialFValues = {
    full_name: "",
    email: "",
    contact_no: "",
    message: "",
  };
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);

  const [addContact] = useAddContactMutation();
  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    handleMultipleInput,
    resetForm,
    valueArray,
  } = useForm(initialFValues, true);

  const handleSubmit = (e) => {
    console.log(e);
    e.preventDefault();
    const formData = new FormData();
    formData.append("full_name", values.full_name);
    formData.append("email", values.email);
    formData.append("contact_no", values.contact_no);
    formData.append("message", values.message);
    addContact(formData)
      .then((res) => {
        setSuccesMessege("Thanks for Contacting Us.");
        setTimeout(() => {
          window.location.reload();
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur. Please Try Again Later.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <Grid container spacing={5} id="contact_us">
      <Grid item md={6} xs={12}>
        <Box className=" bg-gray-200 p-4 opacity-90 backdrop-blur-2xl rounded-tl-[36px] rounded-br-[36px]  hover:opacity-100">
          <Box>
            <Typography variant="h1" className="new-articles-text1">
              <p className=" font-Barlow text-[#006d1b] text-[20px] lg:text-[30px]">
                Contact Us
              </p>
            </Typography>
          </Box>
          <Box>
            <Typography className="small-body-text1">
              <p className=" font-Barlow px-2">
                Dhangadhi skin care center offers you special treatment for
                melasma-pigmentation-dark circles-acne
              </p>
            </Typography>
          </Box>
          <Box style={{ marginTop: "3rem" }}>
            <Form onSubmit={handleSubmit}>
              <Grid container>
                <Controls.Input
                  name="full_name"
                  label="Full Name"
                  value={values.fullName}
                  onChange={handleInputChange}
                  error={errors.fullName}
                />
                <Controls.Input
                  label="Email"
                  name="email"
                  value={values.email}
                  onChange={handleInputChange}
                  error={errors.email}
                />
                <Controls.Input
                  label="Contact No."
                  name="contact_no"
                  value={values.contact_no}
                  onChange={handleInputChange}
                  error={errors.contact_no}
                />

                <Controls.Input
                  label="Message"
                  name="message"
                  value={values.message}
                  onChange={handleInputChange}
                  error={errors.message}
                />
                <Box
                  style={{ marginTop: "12px" }}
                  className="contact-us-button1"
                >
                  <Controls.Button
                    type="submit"
                    text="Submit"
                    onClick={handleSubmit}
                  />
                </Box>
              </Grid>
            </Form>
          </Box>
        </Box>
      </Grid>
      <Grid item md={6} xs={12}>
        <Box className="new-style-margin">
          <Box>
            <Typography className="new-articles-text2" variant="h1">
              <p className=" text-[#006d1b] font-Barlow text-3xl">Contact</p>
            </Typography>
            <Box style={{ marginTop: "1.2rem" }}>
              <Typography className=" text-left text-white">
                <p className="font-Barlow font-light text-sm lg:text-xl">
                  We specialize in skin, nail & hair treatments (
                  transplantation) antiageing therapies,laser surgery and
                  related diseases. Our specialist dermatologists provide
                  affordable quality services.
                </p>
              </Typography>
            </Box>
            <Box style={{ marginTop: "1.5rem" }}>
              <Grid container spacing={7}>
                <Grid item xs={1}>
                  <Box>
                    <Typography className="small-body-text2" variant="h1">
                      <FontAwesomeIcon icon={faPhone} className="icon-design" />
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className="small-body-text2" variant="h1">
                      <FontAwesomeIcon
                        className="icon-design"
                        icon={faEnvelope}
                      />
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className="small-body-text2" variant="h1">
                      <FontAwesomeIcon
                        className="icon-design"
                        icon={faMapMarkerAlt}
                      />
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={10}>
                  <Box style={{ marginTop: "1.5rem" }}>
                    <Typography className="small-body-text2" variant="h1">
                      <p className=" font-poppins">
                        +977-091-520707, 091-527142
                      </p>
                    </Typography>
                  </Box>
                  <Box style={{ marginTop: "2.5rem" }}>
                    <Typography className="small-body-text2" variant="h1">
                      <p className=" font-poppins">info@dhangadhi.com</p>
                    </Typography>
                  </Box>
                  <Box style={{ marginTop: "2.5rem" }}>
                    <Typography className="small-body-text2" variant="h1">
                      <p className=" font-poppins">
                        Chatakpur , Dhangadhi , Kailali, Nepal
                      </p>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          {SuccessMessege === null ? null : (
            <Box className="successmessege">
              <Typography className="messegefonts">
                <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
                {SuccessMessege}
              </Typography>
            </Box>
          )}
          {ErrorMessege === null ? null : (
            <Box className="errormessege">
              <Typography className="messegefonts">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="messegeicon"
                />
                {ErrorMessege}
              </Typography>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Contact_Page;
