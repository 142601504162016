import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { FaChevronRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useGetActiveHomePagesQuery } from "../../../services/homePageSlice";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Product_Header = () => {
  const { data, isLoading, isSuccess, isError, error } =
    useGetActiveHomePagesQuery("getActiveHomePages");

  return (
    <Grid container spacing={7}>
      <Grid item md={7}>
        <Box style={{ marginTop: "5rem" }}>
          <Typography variant="h1" className="body-h1-text">
            Our Doctor
          </Typography>
          <Box style={{ marginTop: "2rem", textAlign: "start" }}>
            <span className="flex">
              <Link to="/" className="footer-service-link1">
                Home
              </Link>
              <FaChevronRight
                style={{
                  marginLeft: "15px",
                  marginRight: "15px",
                  marginTop: "5px",
                }}
                size={12}
              />
              <Link to="/product" className="footer-service-link1">
                Our Doctor
              </Link>
            </span>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={0} md={1}></Grid>
      <Grid item md={4} xs={12}>
        {data === undefined ? (
          <SkeletonTheme color="grey" highlightColor="#444">
            <p>
              <Skeleton height={250} width={300} count={1} />
            </p>
          </SkeletonTheme>
        ) : (
          <img
            src={data.entities[data.ids[0]].image}
            className="logo-image-width image-margin"
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Product_Header;
