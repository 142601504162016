import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";
import { useGetHomePagesByIdQuery } from "../../../../services/homePageSlice";
import BlobToFile from "../forms/utils/blobToFile";
import { useUpdateHomePageMutation } from "../../../../services/homePageSlice";
import urlToFile from "../forms/utils/urlToFile";
import { Box } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBell,
  faSignInAlt,
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const HomePageEditPage = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <EditHomePagePages />
      </Grid>
    </Grid>
  );
};

const EditHomePagePages = () => {
  const { id } = useParams();
  const HomePages = useGetHomePagesByIdQuery(id);
  const [File, setFile] = useState([]);
  const [updateHomePage, setUpdateHomePage] = useState([]);
  const [initialFValues, setInitialFValues] = useState({
    image: [],
    title: "",
    subtitle: "",
    is_active: "false",
  });
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);

  useEffect(() => {
    console.log(HomePages.data);
    if (HomePages.data) {
      if (HomePages.data.image) {
        urlToFile(HomePages.data.image, setFile);
      }

      setInitialFValues({
        image: File,
        title: HomePages.data.title,
        subtitle: HomePages.data.subtitle,
        is_active: HomePages.data.is_active.toString(),
      });
    }
  }, [HomePages.data]);

  const { values, handleImageUpload, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    true
  );

  const [editHomePage] = useUpdateHomePageMutation();

  useEffect(() => {
    handleImageUpload("image", File);
  }, [File]);

  const history = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    var file = BlobToFile(values.image, "homePage");
    var isTrueSet = values.is_active === "true";
    if (file.length !== 0) {
      formData.append("image", file, "homePage.jpg");
    }
    if(values.title)
      formData.append("title", values.title);
    if(values.subtitle)
      formData.append("subtitle", values.subtitle);
    formData.append("is_active", isTrueSet);
    formData.append("id", id);
    editHomePage(formData)
      .then((res) => {
        setSuccesMessege("Successfully Updated Home Page Data.");
        setTimeout(() => {
          history("/dashboard/home-pages");
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur. Please Try Again Later.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <Box className="table-design-background">
      <Typography className="dashboard-home-page-text">
        Update Home Page Details
      </Typography>
      <Box style={{ padding: "1.5rem" }}>
        <Form onSubmit={handleSubmit}>
          <Grid container spacing={8}>
            <Grid item xs={6} style={{ textAlign: "start" }}>
              <Controls.Input
                name="title"
                label="Title"
                value={values.title}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="subtitle"
                label="Sub-Title"
                value={values.subtitle}
                onChange={handleInputChange}
              />
              <Controls.RadioGroup
                name="is_active"
                label="Active"
                value={values.is_active}
                onChange={handleInputChange}
                items={[
                  { id: "false", title: "False" },
                  { id: "true", title: "True" },
                ]}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Image setFile={setFile} aspectRatio={1 / 1} />
            </Grid>
            <Grid item style={{ textAlign: "start", marginTop: "-3.2rem" }}>
              <Controls.Button
                type="submit"
                text="Submit"
                backgroundColor="#1b284b"
                onClick={handleSubmit}
              />
            </Grid>
          </Grid>
        </Form>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default HomePageEditPage;
