import React, { useEffect, useState } from "react";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import { Box, Grid, Typography } from "@mui/material";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";
import { useAddServiceMutation } from "../../../../services/serviceSlice";
import BlobToFile from "../forms/utils/blobToFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBell,
  faSignInAlt,
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const AddService = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <AddServicePages />
      </Grid>
    </Grid>
  );
};

const AddServicePages = () => {
  const [File, setFile] = useState([]);
  const initialFValues = {
    image: [],
    service_name: "",
    price: null,
    description: "",
    no_of_visit: null,
    time_frame: null,
  };
  const { values, handleImageUpload, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    false
  );
  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);
  const history = useNavigate();

  const [addService] = useAddServiceMutation();

  useEffect(() => {
    handleImageUpload("image", File);
  }, [File]);

  const thisFile = (e) => {
    console.log(e.target.files[0]);
    console.log(typeof e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    var file = BlobToFile(values.image, "service");
    if (file.length !== 0) {
      formData.append("image", file, "service.jpg");
    }
    if(values.service_name)
    {
      formData.append("service_name", values.service_name);
    }
    if(values.price)
    {
      formData.append("price", values.price);
    }
    if(values.description)
    {
      formData.append("description", values.description);
    }
    if(values.no_of_visit)
    {
      formData.append("no_of_visit", values.no_of_visit);
    }
    if(values.time_frame)
    {
      formData.append("time_frame", values.time_frame);
    }
    addService(formData)
      .then((res) => {
        setSuccesMessege("Successfully Added Services Data.");
        setTimeout(() => {
          history("/dashboard/services");
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur. Please Try Again Later.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <Box className="table-design-background">
      <Typography className="dashboard-home-page-text">Add Services</Typography>
      <Box style={{ padding: "1.5rem" }}>
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={6}>
              <Controls.Input
                name="service_name"
                label="Service Name"
                value={values.service_name}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="price"
                label="Price"
                value={values.price}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="description"
                label="Description"
                value={values.description}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="no_of_visit"
                label="No of Visit"
                value={values.no_of_visit}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="time_frame"
                label="Time Frame"
                value={values.time_frame}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Image setFile={setFile} aspectRatio={373 / 280} />
            </Grid>
            <Grid style={{ marginTop: "12px" }}>
              <Controls.Button
                type="submit"
                text="Submit"
                backgroundColor="#1b284b"
                onClick={handleSubmit}
              />
            </Grid>
          </Grid>
        </Form>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default AddService;
