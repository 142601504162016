import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Grow,
  MenuItem,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import Divider from "../../../static/images/divider.png";
import Face from "../../../static/images/face.jpeg";
import { Link } from "react-router-dom";
import { TbVideo, TbUser } from "react-icons/tb";
import {
  HiArrowNarrowRight,
  HiOutlineMail,
  HiOutlineLocationMarker,
} from "react-icons/hi";
import { useGetServicesQuery } from "../../../services/serviceSlice";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Service_Page = () => {
  const { data, isLoading, isSuccess, isError, error } =
    useGetServicesQuery("getServices");

  return (
    <Box className="servicesBox">
      <Grid container>
        <Grid md={7} xs={12} style={{ margin: "0 auto" }}>
          <Box>
            <Box>
              <Typography>
                <h1>Our Popular Skin Care</h1>
              </Typography>
            </Box>

            <Box
              style={{ marginTop: "1rem", marginLeft: "15rem" }}
              className="curve-rope"
            >
              <img src={Divider} />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box style={{ marginTop: "3rem" }}>
        <Grid container spacing={2}>
          {data === undefined ? (
            <>
              <Grid item md={4} xs={12}>
                <Box className="hover-display-information">
                  <SkeletonTheme color="grey" highlightColor="#444">
                    <p>
                      <Skeleton height={250} width={300} count={1} />
                    </p>
                  </SkeletonTheme>
                  <Box className="card-down-design">
                    <Typography className="face-treatment-text">
                      <TbUser
                        style={{
                          position: "relative",
                          marginRight: "5px",
                          top: "3px",
                        }}
                      />
                      <Skeleton />
                    </Typography>
                    <Box className="display-block-text">
                      <Typography className="small-body-text1">
                        <Skeleton count={3} />
                      </Typography>
                      <Box style={{ marginTop: "1rem" }}>
                        <Skeleton count={2} />
                      </Box>
                      <Box className="medium-box-design"></Box>
                      <Box className="small-box-design"></Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={4} xs={12}>
                <Box className="hover-display-information">
                  <SkeletonTheme color="grey" highlightColor="#444">
                    <p>
                      <Skeleton height={250} width={300} count={1} />
                    </p>
                  </SkeletonTheme>
                  <Box className="card-down-design">
                    <Typography className="face-treatment-text">
                      <TbUser
                        style={{
                          position: "relative",
                          marginRight: "5px",
                          top: "3px",
                        }}
                      />
                      <Skeleton />
                    </Typography>
                    <Box className="display-block-text">
                      <Typography className="small-body-text1">
                        <Skeleton count={3} />
                      </Typography>
                      <Box style={{ marginTop: "1rem" }}>
                        <Skeleton count={2} />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={4} xs={12}>
                <Box className="hover-display-information">
                  <SkeletonTheme color="grey" highlightColor="#444">
                    <p>
                      <Skeleton height={250} width={300} count={1} />
                    </p>
                  </SkeletonTheme>
                  <Box className="card-down-design">
                    <Typography className="face-treatment-text">
                      <TbUser
                        style={{
                          position: "relative",
                          marginRight: "5px",
                        }}
                      />
                      <Skeleton />
                    </Typography>
                    <Box className="display-block-text">
                      <Typography className="small-body-text1">
                        <Skeleton count={3} />
                      </Typography>
                      <Box style={{ marginTop: "1rem" }}>
                        <Skeleton count={2} />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </>
          ) : (
            <>
              {data.ids.map((key) => (
                <Grid
                  item
                  md={4}
                  xs={12}
                  key={key}
                  className="hover-display-info"
                >
                  <Box className="hover-display-information">
                    <img
                      src={data.entities[key].image}
                      className="logo-image-width image-design-hover"
                      alt={data.entities[key].service_name}
                    />
                    <Box className="card-down-design">
                      <Typography className="face-treatment-text">
                        {data.entities[key].service_name}
                      </Typography>
                      <Box className="display-block-text">
                        <Typography className="small-body-text1 text-overflow-design">
                          {data.entities[key].description}
                        </Typography>
                        <Box
                          style={{
                            marginTop: "1rem",
                          }}
                        >
                          <Link
                            to={`services/${data.entities[key].id}`}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            Read More
                            <HiArrowNarrowRight className="contact-us-button-icon" />
                          </Link>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default Service_Page;
