import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import Sidebar from "../../component/sidebar";
import Header from "../../component/header";
import Controls from "../forms/controls/Controls";
import { useForm, Form } from "../forms/useForm";
import { useGetServicesByIdQuery } from "../../../../services/serviceSlice";
import BlobToFile from "../forms/utils/blobToFile";
import { useUpdateServiceMutation } from "../../../../services/serviceSlice";
import urlToFile from "../forms/utils/urlToFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBell,
  faSignInAlt,
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const ServiceEditPage = () => {
  return (
    <Grid container className="dashboard-full-background">
      <Grid item xs={2} spacing={4}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Header />
        <EditServicePages />
      </Grid>
    </Grid>
  );
};

const EditServicePages = () => {
  const { id } = useParams();
  const Services = useGetServicesByIdQuery(id);
  const [File, setFile] = useState([]);
  const [updateService, setUpdateService] = useState([]);
  const [initialFValues, setInitialFValues] = useState({
    image: [],
    service_name: "",
    price: 0,
    description: "",
    no_of_visit: 0,
    time_frame: 0,
  });

  const [SuccessMessege, setSuccesMessege] = useState(null);
  const [ErrorMessege, setErrorMessege] = useState(null);
  const history = useNavigate();

  useEffect(() => {
    if (Services.data) {
      urlToFile(Services.data.image, setFile);
      setInitialFValues({
        image: File,
        service_name: Services.data.service_name,
        price: Services.data.price,
        description: Services.data.description,
        no_of_visit: Services.data.no_of_visit,
        time_frame: Services.data.time_frame,
      });
    }
  }, [Services.data]);

  console.log(initialFValues);
  const { values, handleImageUpload, handleInputChange } = useForm(
    initialFValues,
    true,
    false,
    true
  );

  console.log(values);

  const [editService] = useUpdateServiceMutation();

  useEffect(() => {
    handleImageUpload("image", File);
  }, [File]);

  const thisFile = (e) => {
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData()
    var file = BlobToFile(values.image, "service");
    if (file.length !== 0) {
      formData.append("image", file, "service.jpg");
    }
    if(values.service_name)
      formData.append("service_name", values.service_name);
    if(values.price)
      formData.append("price", values.price);
    if(values.description)
      formData.append("description", values.description);
    if(values.no_of_visit)  
      formData.append("no_of_visit", values.no_of_visit);
    if(values.time_frame)
      formData.append("time_frame", values.time_frame);
    formData.append("id", id);
    editService(formData)
      .then((res) => {
        setSuccesMessege("Successfully Updated Services Data.");
        setTimeout(() => {
          history("/dashboard/services");
        }, [3000]);
      })
      .catch((err) => {
        setErrorMessege("Some Error Occur. Please Try Again Later.");
        setTimeout(() => {
          setErrorMessege(null);
        }, [3000]);
      });
  };

  return (
    <Box className="table-design-background">
      <Typography className="dashboard-home-page-text">
        Update Services List
      </Typography>
      <Box style={{ padding: "1.5rem" }}>
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={6}>
              <Controls.Input
                name="service_name"
                label="Service Name"
                value={values.service_name}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="price"
                label="Price"
                value={values.price}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="description"
                label="Description"
                value={values.description}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="no_of_visit"
                label="No of Visit"
                value={values.no_of_visit}
                onChange={handleInputChange}
              />
              <Controls.Input
                name="time_frame"
                label="Time Frame"
                value={values.time_frame}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Controls.Image setFile={setFile} aspectRatio={373 / 280} />
            </Grid>
            <Grid style={{ marginTop: "12px" }}>
              <Controls.Button
                type="submit"
                text="Submit"
                backgroundColor="#1b284b"
                onClick={handleSubmit}
              />
            </Grid>
          </Grid>
        </Form>
        {SuccessMessege === null ? null : (
          <Box className="successmessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon icon={faCheckCircle} className="messegeicon" />
              {SuccessMessege}
            </Typography>
          </Box>
        )}
        {ErrorMessege === null ? null : (
          <Box className="errormessege">
            <Typography className="messegefonts">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="messegeicon"
              />
              {ErrorMessege}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ServiceEditPage;
