import React from "react";
import { useGetBlogsQuery } from "../../../services/blogSlice";
import {
  Box,
  Button,
  Grid,
  Grow,
  MenuItem,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import {
  HiArrowNarrowRight,
  HiOutlineMail,
  HiOutlineLocationMarker,
} from "react-icons/hi";
import Divider from "../../../static/images/divider.png";
import { Link } from "react-router-dom";
import Articles from "../../../static/images/articles.jpeg";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Blog_Page = () => {
  const { data, isLoading, isSuccess, isError, error } =
    useGetBlogsQuery("getBlogs");

  if (isSuccess) {
    if (data.ids.length > 3) {
      data = data.ids.slice(0, 3);
    }
  }

  return (
    <>
      <Grid container>
        <Grid md={7} xs={12} style={{ margin: "0 auto", marginTop: "4rem" }}>
          <Box>
            <Box>
              <Typography className="text-fonts1">NEWS & UPDATES</Typography>
            </Box>
            <Box>
              <Typography variant="h1" className="body-font-size1">
                Latest News & Articles
              </Typography>
            </Box>
            <Box
              style={{ marginTop: "1rem", marginLeft: "18rem" }}
              className="curve-rope"
            >
              <img src={Divider} />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box style={{ marginTop: "3rem" }}>
        <Grid container spacing={3}>
          {data === undefined ? (
            <>
              <Grid item md={4} xs={12}>
                <Box className="new-cards-shadow">
                  <SkeletonTheme color="grey" highlightColor="#444">
                    <p>
                      <Skeleton height={250} width={300} count={1} />
                    </p>
                  </SkeletonTheme>
                  <Box style={{ padding: "2rem" }}>
                    <Typography className="new-articles-text" variant="h1">
                      <Skeleton />
                    </Typography>
                    <Box style={{ marginTop: "1rem" }}>
                      <Skeleton />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={4} xs={12}>
                <Box className="new-cards-shadow">
                  <SkeletonTheme color="grey" highlightColor="#444">
                    <p>
                      <Skeleton height={250} width={300} count={1} />
                    </p>
                  </SkeletonTheme>
                  <Box style={{ padding: "2rem" }}>
                    <Typography className="new-articles-text" variant="h1">
                      <Skeleton />
                    </Typography>
                    <Box style={{ marginTop: "1rem" }}>
                      <Skeleton />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={4} xs={12}>
                <Box className="new-cards-shadow">
                  <SkeletonTheme color="grey" highlightColor="#444">
                    <p>
                      <Skeleton height={250} width={300} count={1} />
                    </p>
                  </SkeletonTheme>
                  <Box style={{ padding: "2rem" }}>
                    <Typography className="new-articles-text" variant="h1">
                      <Skeleton />
                    </Typography>
                    <Box style={{ marginTop: "1rem" }}>
                      <Skeleton />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </>
          ) : (
            <>
              {data.ids.map((key) => (
                <Grid item md={4} xs={12}>
                  <Box className="new-cards-shadow">
                    <img
                      src={data.entities[key].image}
                      className="logo-image-width"
                    />
                    <Box style={{ padding: "2rem" }}>
                      <Typography className="new-articles-text" variant="h1">
                        {data.entities[key].title}
                      </Typography>
                      <Box style={{ marginTop: "1rem" }}>
                        <Link
                          to={`/blogs/${data.entities[key].id}`}
                          className="text-fonts1"
                        >
                          Read More{" "}
                          <HiArrowNarrowRight className="contact-us-button-icon" />
                        </Link>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default Blog_Page;
