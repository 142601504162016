import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";

import { apiSlice } from "./apiSlice";

const blogsAdapter = createEntityAdapter()

const initialState = blogsAdapter.getInitialState()

export const blogSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getBlogs: builder.query({
            query: () => '/blogs/blog/',
            transformResponse: responseData => {
                console.log(blogsAdapter.setAll(initialState, responseData))
                return blogsAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => 
            [
                { type: 'Blogs', id: "LIST" },
                ...result.ids.map(id => ({ type: 'Blogs', id }))
            ]
        }),
        getBlogsById: builder.query({
            query: (id)=>({
                url:`/blogs/blog/${id}/`,
                method: 'GET',
            }),
            providesTags: ['Blogs']
        }),
        addBlog: builder.mutation({
            query: (blog) => {
            console.log(blog);
            return{
                url: '/blogs/blog/',
                method: 'POST',
                body: blog
            }
        },
            invalidatesTags: ['Blogs']
        }),
        updateBlog: builder.mutation({
           
            query: (updateBlog) => {
                console.log("here");
                console.log(updateBlog);
                return{
                url: `/blogs/blog/${updateBlog.get('id')}/`,
                method: 'PATCH',
                body: updateBlog
                }
            },
            invalidatesTags: ['Blogs']
        }),
        deleteBlog: builder.mutation({
            query: ({ id }) => {
                console.log(id);
                return{
                url: `/blogs/blog/${id}/`,
                method: 'DELETE',
                body: id
            }},
            invalidatesTags: ['Blogs']
        }),
        getCount: builder.query({
            query: () => {
                return{
                url: `/blogs/blog/get_count/`,
                method: 'GET',
            }},
            invalidatesTags: ['Blogs']
        }),
        
    })
})

export const {
    useGetBlogsQuery,
    useAddBlogMutation,
    useUpdateBlogMutation,
    useDeleteBlogMutation,
    useGetBlogsByIdQuery,
    useGetCountQuery,
} = blogSlice

// returns the query result object
export const selectBlogsResult = blogSlice.endpoints.getBlogs.select()

// Creates memoized selector
const selectBlogssData = createSelector(
    selectBlogsResult,
    blogsResult => blogsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllBlogs,
    selectById: selectBlogById,
    selectIds: selectIds
    // Pass in a selector that returns the posts slice of state
} = blogsAdapter.getSelectors(state => selectBlogssData(state) ?? initialState)