import { Button } from "@material-ui/core";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/system";

const TextButton = ({
  color = "black",
  backgroundColor = "white",
  fontSize = "12px",
  fontFamily = null,
  buttonText = "Add",
  buttonIcon = <AddIcon style={{ paddingRight: "8px", fontSize: "18px" }} />,
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  return (
    <>
      <Box style={{ textAlign: "end",paddingRight:"1.5rem" }}>
        <Button
          style={{
            color: `${color}`,
            backgroundColor: `${backgroundColor}`,
            fontSize: `${fontSize}`,
            fontFamily: `${fontFamily}`,
            padding: "6px 10px",
            borderRadius: "10px",
            boxShadow: "0px 8px 15px rgba(0.1, 0, 0, 0.1)",
            boxShadow: isHovering ? "0px 6px 10px rgba(0.1, 0, 0, 0.2)" : "",
            transition: "all 0.3s ease 0.5s",
            border: "1px solid black",
            marginTop: "1.5rem",
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {buttonIcon}
          {buttonText}
        </Button>
      </Box>
    </>
  );
};

export default TextButton;
