import { Button, Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import Logo from "../../../static/images/logo.png";
// import { FaFacebook, FaFacebookF } from "react-icons/fa";
import { BsInstagram, BsTwitter, BsYoutube, BsFacebook } from "react-icons/bs";
import { Link } from "react-router-dom";
import {
  HiArrowNarrowRight,
  HiOutlineMail,
  HiOutlineLocationMarker,
} from "react-icons/hi";
import { FiPhoneCall } from "react-icons/fi";
import { useGetServicesQuery } from "../../../services/serviceSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faTiktok,
  faTwitter,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  let newDate = new Date();
  let date = newDate.getFullYear();
  const { data, isLoading, isSuccess, isError, error } =
    useGetServicesQuery("getServices");
  console.log(data);

  const [form, setForm] = useState({ email: "" });
  const handleChange = (e) => {
    const { value, name } = e.target;
    setForm({ ...form, [name]: value });
  };
  const handleSubmit = () => {
    //POST METHOD
    console.log("email:", form);
  };
  return (
    <Box>
      <Box className="fixed-design">
        <Box>
          <a href="https://www.facebook.com/dhangadhiskincare" target="_blanck">
            <FontAwesomeIcon
              icon={faFacebookF}
              className="icon-footer-design1"
            />
          </a>
        </Box>
        <Box>
          <a
            target="_blanck"
            href="https://www.instagram.com/dhangadhiskincare/?fbclid=IwAR2ok8xU-KEg0RNP7G3Tkxg93mg3T9JY7D3L-AE1ZTbWAqd9pq-3Mm188I8"
          >
            <FontAwesomeIcon
              icon={faInstagram}
              className="icon-footer-design2"
            />
          </a>
        </Box>
        <Box>
          <a target="_blanck" href="https://wa.me/9779860557590">
            <FontAwesomeIcon
              icon={faWhatsapp}
              className="icon-footer-design3"
            />
          </a>
        </Box>
        <Box>
          <a
            target="_blanck"
            href="https://www.tiktok.com/@dhangadhiskinhospital?_t=8YJ29xXErmE&_r=1"
          >
            <FontAwesomeIcon icon={faTiktok} className="icon-footer-design4" />
          </a>
        </Box>
      </Box>
      <Container>
        <Box className="footer-border-bottom">
          <Grid container spacing={3}>
            <Grid item md={3} xs={12}>
              <Box style={{ textAlign: "start" }}>
                <Box>
                  <img src={Logo} alt="Logo" style={{ width: "52%" }} />
                </Box>
                <Box>
                  <Typography className="small-body-text3">
                    <p className=" font-poppins leading-snug text-[15px]">
                      We specialize in skin, nail & hair treatments, hair
                      transplantation, antiageing therapies,laser surgery and
                      related diseases. Our specialist dermatologists provide
                      affordable quality services.
                    </p>
                  </Typography>
                </Box>
                <Box style={{ marginTop: "1.2rem" }}>
                  <Grid container spacing={3} className=" space-x-0.5">
                    <Grid item xs={2}>
                      <a
                        href="https://www.facebook.com/dhangadhiskincare"
                        target="_blanck"
                      >
                        <FontAwesomeIcon
                          icon={faFacebookF}
                          className=" bg-blue-600 py-1.5 px-2.5 rounded-md text-white text-xl"
                        />
                      </a>
                    </Grid>
                    <Grid item xs={2}>
                      <a
                        target="_blanck"
                        href="https://www.instagram.com/dhangadhiskincare/?fbclid=IwAR2ok8xU-KEg0RNP7G3Tkxg93mg3T9JY7D3L-AE1ZTbWAqd9pq-3Mm188I8"
                      >
                        <FontAwesomeIcon
                          icon={faInstagram}
                          className=" bg-orange-700 py-1.5 px-2.5 rounded-md text-white text-xl"
                        />
                      </a>
                    </Grid>
                    <Grid item xs={2}>
                      <a target="_blanck" href="https://wa.me/9779860557590">
                        <FontAwesomeIcon
                          icon={faWhatsapp}
                          className=" bg-green-500 py-1.5 px-2.5 rounded-md text-white text-xl"
                        />
                      </a>
                    </Grid>
                    <Grid item xs={2}>
                      <a
                        target="_blanck"
                        href="https://www.tiktok.com/@dhangadhiskinhospital?_t=8YJ29xXErmE&_r=1"
                      >
                        <FontAwesomeIcon
                          icon={faTiktok}
                          className=" bg-black py-1.5 px-2.5 rounded-md text-white text-xl"
                        />
                      </a>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item md={3} xs={12}>
              <Box style={{ textAlign: "start" }}>
                <Grid container>
                  <Grid item md={3} xs={0}></Grid>
                  <Grid item md={9} xs={12}>
                    <Typography className="footer-service-text">
                      <p className=" font-Barlow">Our Services</p>
                    </Typography>
                    <Box style={{ marginTop: "1rem" }}>
                      {data ? (
                        <>
                          {data.ids.slice(-6).map((key) => (
                            <Link
                              to={`/services/${data.entities[key].id}`}
                              key={key}
                              className="footer-service-link"
                            >
                              <p className=" font-Barlow">
                                {data.entities[key].service_name}
                              </p>
                            </Link>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box style={{ textAlign: "start" }}>
                <Typography className="footer-service-text">
                  <p className=" font-Barlow">Contact</p>
                </Typography>
                <Box style={{ marginTop: ".2rem" }}>
                  <Grid container spacing={7} className=" flex items-center">
                    <Grid item xs={1}>
                      <Box>
                        <Typography className="small-body-text2" variant="h1">
                          <FontAwesomeIcon
                            icon={faPhone}
                            className="icon-design"
                          />
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className="small-body-text2" variant="h1">
                          <FontAwesomeIcon
                            icon={faEnvelope}
                            className="icon-design"
                          />
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className="small-body-text2" variant="h1">
                          <FontAwesomeIcon
                            className="icon-design"
                            icon={faMapMarkerAlt}
                          />
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={9}>
                      <Box style={{ marginTop: "1rem" }}>
                        <Typography
                          className="footer-service-link"
                          variant="h1"
                        >
                          <p className=" font-Barlow">
                            +977-091-520707, 091-527142
                          </p>
                        </Typography>
                      </Box>
                      <Box style={{ marginTop: "2rem" }}>
                        <Typography
                          className="footer-service-link"
                          variant="h1"
                        >
                          <p className=" font-Barlow text-[15px]">
                            dhangadhiskincare@gmail.com
                          </p>
                        </Typography>
                      </Box>
                      <Box style={{ marginTop: "2rem" }}>
                        <Typography
                          className="footer-service-link"
                          variant="h1"
                        >
                          <p className=" font-Barlow">
                            Chatakpur , Dhangadhi , Kailali, Nepal
                          </p>
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item md={4} xs={12} lg={3}>
              <Box className=" text-start">
                <Typography className="footer-service-text">
                  <p className=" font-Barlow">Join Now</p>
                </Typography>
                <Box style={{ marginTop: "1rem" }}>
                  <Typography className="small-body-text3">
                    <p className=" font-Barlow">
                      Join now to get the Weekly update.
                    </p>
                  </Typography>
                  <Box className="mt-[1rem] font-BArlow space-y-2.5">
                    <input
                      value={form.email}
                      onChange={handleChange}
                      name="email"
                      type="email"
                      placeholder="Enter Your Email"
                      className="border-2 border-[#006d1b] py-2 mx-1 rounded-sm px-3 "
                    />
                    <button
                      className=" py-2.5 bg-[#006d1b] text-white  hover:bg-[#5a187a] duration-200 subs-button"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className="footer-bottom-bar">
          <Typography className="footer-bottom-bar-text">
            <p className=" font-poppins">
              Copyright © {date} Dhangadi Skin Care & Aesthetic Center. All
              rights
            </p>
            reserved.{" "}
            <a
              href="https://www.developergd.com/"
              target="_blanck"
              className=" font-poppins"
            >
              Design and Developed by G & D Developers.
            </a>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
